/***
 *
 *   PROGRESS STEPS
 *   Steps are used to indicate the current point in a
 *   multi-stage process, such as filling in a long form
 *
 *   PROPS
 *   items: array of objects containing keys: name, url
 *   and completed (bool)
 *
 **********/

import React from 'react';
import variables from 'components/global/color.scss';
import "react-step-progress-bar/styles.css";
import {ProgressBar, Step} from "react-step-progress-bar";
import Style from './steps.module.scss';
import {MyStep} from "./my_step";
import ClassNames from "classnames";

export function ProjectSteps(props) {
    const css = ClassNames([
        Style.bar,
        props.className
    ]);
    const transitionStyles = {
        entering: {transform: "scale(1.5)"},
        entered: {transform: "scale(1)"},
        exiting: {transform: "scale(1.5)"},
        exited: {transform: "scale(1)"}
    };

    return (
        <div
            className={css}
        >
            <ProgressBar
                percent={props.percentCompleted}
                filledBackground={variables.cprimary}
                unfilledBackground={variables.cgray}
            >
                {props.items &&
                    Object.keys(props.items).map(item => {
                        item = props.items[item];
                        return (
                            <Step
                                key={item.name}
                            >
                                {({accomplished, transitionState, index}) => (
                                    <MyStep
                                        style={transitionStyles[transitionState]}
                                        className={`customStep ${accomplished} ? "accomplished" : ""`}
                                        name={item.name}
                                        icon={item.icon}
                                        accomplished={accomplished}
                                        isActive={index === props.activeIndex}
                                    />
                                )}

                            </Step>
                        );
                    })}
            </ProgressBar>
        </div>
    )
}
