/***
 *
 *   Address Page
 *
 **********/

import React, { useEffect, useState } from 'react';
import { Animate, Feedback, Button, History, Table, Card, Icon, Image } from 'components/lib';
import usePollingEffect, { fetchData } from '../../components/hooks/polling';
import sageLogo from '../../components/logo/images/SAGE Transparent Black Cropped.png';
import Style from './sage_landing.module.scss';

async function finish() {
    History.push('/sage/new_request');
}

export function SageLanding(props) {
    const [data, setData] = useState({});
    const [tableData, setTableData] = useState([]);
    useEffect(() => {
        if (Object.keys(data).length === 0)
            return;
        const res = {
            ...data.data,
        };
        const newData = [];
        for (let i of Object.keys(res)) {
            if (res[i].project_status !== 'Complete') {
                res[i].action = <Button text="View Status" small action={openRow(res[i].project_id)} />
                newData.push(res[i]);
            }
            else {
                res[i].action = <Button text="View Report" small action={openRow(res[i].project_id)} />
                newData.push(res[i]);

            }

        }
        setTableData(newData);
    }, [data]);


    usePollingEffect(
        async () => fetchData(setData),
        [],
        {
            interval: 15000,
            onCleanUp: () => {
                console.log('Cleanup on unmount or dependency change');
            }
        }
    );

    function openRow(id) {
        return () => History.push('/sage/project/' + id);
    }

    return (
        <Animate type='pop'>
            <Card
                title='About SAGE'
            >
                <div className={Style.description}>
                    <Image
                        source={sageLogo}
                        size={150}
                        className={Style.sage_logo}
                    />
                    <p>SAGE delivers asset level measurements of greenhouse gases for Scope 1 and 2.
                        Using direct, sensor measurements, an up-to-date inventory of emissions can be created in days with clear, transparent–and most importantly–unbiased accuracy.
                    </p>
                </div>

            </Card>
            <Card
                title='SAGE Project Library'

            >
                <div className={Style.table_header}>
                    <h3>Click the button below to view the report: </h3>
                    <Icon
                        image='log-in'
                        size={18}
                        className={Style.table_header_icn}
                    />
                </div>
                <Table
                    data={tableData}
                    show={['project_name', 'project_type', 'project_status', 'last_modified', 'action']}
                    // loading={data?.loading}
                    search
                // actions={{custom: [{icon: 'log-in', action: openRow}]}}
                />
            </Card>
            <div className={Style.button_holder}>
                <Button
                    color='light'
                    // icon='plus'
                    text='Generate Report'
                    size={200}
                    action={finish}
                />
            </div>

            <Feedback />
        </Animate>
    );
}
