/***
*
*   VIEW
*   The view houses global components that are common to all views
*   (notification, modal), handles errors and renders the layout
*
**********/

import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
  AppLayout, AuthLayout, HomeLayout, MasterLayout,
  OnboardingLayout, Modal, Notification, History
} from '../lib';

import './scss/normalize.scss';
import './scss/view.scss';
import './scss/typography.scss';

export const ViewContext = React.createContext();

export function View(props) {
  const [notification, setNotification] = useState({ visible: 'hide', autoclose: true });
  const [modal, setModal] = useState({});
  const timeoutRef = useRef(null);

  const layouts = {
    app: AppLayout,
    auth: AuthLayout,
    master: MasterLayout,
    home: HomeLayout,
    onboarding: OnboardingLayout
  };

  document.title = props.title;
  let Layout = props.layout ? layouts[props.layout] : AppLayout;

  const showNotification = useCallback((text, type, autoclose, format, icon) => {
    setNotification({
      text: text,
      type: type,
      show: true,
      format: format,
      icon: icon,
      autoclose: autoclose
    });

    if (autoclose) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(hideNotification, 2000);
    }
  }, []);

  const hideNotification = useCallback(() => {
    setNotification({
      text: '',
      type: '',
      show: false,
      format: null,
      icon: null,
      autoclose: true
    });
  }, []);

  const showModal = useCallback((content, callback) => {
    setModal(prevModal => ({
      ...prevModal,
      ...content,
      show: true,
      callback: callback
    }));
  }, []);

  const hideModal = useCallback((cancel) => {
    setModal(prevModal => {
      if (!cancel && prevModal.callback) {
        prevModal.callback(prevModal.form);
      }
      return {
        title: null,
        text: null,
        buttonText: null,
        url: null,
        method: null,
        show: false,
      };
    });
  }, []);

  const handleError = useCallback((err) => {
    let message = 'There was a glitch in the matrix – please try again';

    if (err) {
      message = err.toString();

      if (err.response) {
        if (err.response.data?.message) {
          message = err.response.data.message;
        }

        if (err.response.status) {
          switch (err.response.status) {
            case 401:
              History.push('/sign_in');
              break;
            case 404:
              History.push('/notfound');
              break;
            case 402:
              History.push('/account/upgrade?plan=' + err.response.data.plan);
              break;
            default:
              console.error(err);
              showNotification(message, 'error', false);
              break;
          }
        }
      }
    }
  }, [showNotification]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const context = {
    notification: {
      show: showNotification,
      hide: hideNotification,
      data: notification,
    },
    modal: {
      show: showModal,
      hide: hideModal,
      data: modal,
    },
    handleError: handleError
  };

  if (!props.display) return false;

  return (
    <ViewContext.Provider value={context}>
      {notification.show && <Notification {...notification} />}
      {modal.show && <Modal {...modal} />}
      <Layout title={props.title} data={props.data}>
        {props.display}
      </Layout>
    </ViewContext.Provider>
  );
}