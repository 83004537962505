import React, { useState } from "react";
import { TfiExchangeVertical } from "react-icons/tfi";

import styles from "./SwitchCard.module.scss";
import { History } from "components/lib";

export function SwitchCard({ logo, title, description }) {
    const [isSage, setIsSage] = useState(true);

    const handleClick = () => {
        if (isSage) {
            History.push("/sage")
        } else {
            History.push("/rest");
        }
        setIsSage(!isSage);
    };
    return (
        <div className={styles.switchCard} onClick={handleClick}>
            <div className={styles.logoWrapper}>
                <img src={logo} alt={`${title} logo`} />
            </div>
            <div className={styles.contentWrapper}>
                <div className={styles.cardTitle}>
                    {isSage ? "SAGE" : "REST"}
                    <TfiExchangeVertical className={styles.icon} />
                </div>
                <div className={styles.cardDescription}> {isSage
                    ? "Spatial Asset Greenhouse Gas Emissions (SAGE)"
                    : "Real Estate Sustainability Tool (REST)"}</div>
            </div>
        </div>
    )
}