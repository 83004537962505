/***
*
*   ONBOARDING
*   Example of onboarding flow*
*
**********/

import React, { Fragment, useContext } from 'react';
import { AuthContext, Onboarding, Form, Image } from 'components/lib';
import mic from "./images/microphone.png";

export function OnboardingView(props) {

  const context = useContext(AuthContext);

  return (
    <Onboarding
      save
      onFinish='/dashboard'
      views={[
        {
          name: 'Getting Started',
          // description: `Welcome, ${context.user.name}!`,
          description: `Welcome!`,
          component: <Welcome />
        },
        {
          name: 'Invite your team',
          description: 'Floodlight is more fun when you invite your team.',
          component: <InviteUsers />,
        }
      ]} />
  )
}

function Welcome() {

  return (
    <Fragment>

      <p>Welcome, Here we will walk you through some first steps!</p>

      <p>Our Platform is still in Beta, with updates daily. If you have any issues or find any bugs, please let us know with the Feedback (♥) button.</p>

      <Image source={mic} />

    </Fragment>
  )
}

function InviteUsers(props) {

  return (
    <Form
      data={{
        email: {
          label: 'Email',
          type: 'email',
          required: true,
        }
      }}
      buttonText='Send Invite'
      url='/api/invite'
      method='POST'
    />
  )
}