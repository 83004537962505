const XLSX = require('xlsx');

const generateExcelTemplate = (type) => {
    const workbook = XLSX.utils.book_new();
    let data;

    if (type === 'coordinates') {
        data = [
            ['Asset Name', 'Asset Type', 'Latitudes', 'Longitude', 'Asset Owner', 'Brief Description'],
            ['Asset Name1', 'Asset Type 1', 'Latitudes 2', 'Longitude 1', 'Asset Owner 1', 'Brief Description 1']
        ];
    } else {
        data = [
            ['Asset Name', 'Industry', 'Address', 'Asset Owner', 'Brief Description'],
            ['Asset Name 1', 'Industry 1', 'Address 1', 'Asset Owner 1', 'Brief Description 1']
        ];
    }

    const worksheet = XLSX.utils.aoa_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    return dataBlob;
};

const downloadExcelTemplate = (type) => {
    const dataBlob = generateExcelTemplate(type);
    const fileName = `${type}_template.xlsx`;

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // For IE
        window.navigator.msSaveOrOpenBlob(dataBlob, fileName);
    } else {
        // For other browsers
        const url = window.URL.createObjectURL(dataBlob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        window.URL.revokeObjectURL(url);
    }
};

module.exports = {
    generateExcelTemplate,
    downloadExcelTemplate
};