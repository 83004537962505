import React from 'react';
import Style from "./map_search_bar_results.module.scss";
import AddressIcon from "./icons/address.png";
import LeftIcon from "./icons/arrow_left.svg";
import RightIcon from "./icons/arrow_right.svg";
import SelectIcon from "./icons/enter.svg";
import EscapeIcon from "./icons/esc.svg";

const iconMap = {
    'address': AddressIcon
}

/**
 * MapSearchBarResults component
 *
 * @param {Object} props - Component props
 * @param {Object[]} props.results - Array of search result objects
 * @param {Function} props.callback - Callback function when result is clicked
 *
 * Renders search results from map search bar query. Displays result name and icon.
 * Allows navigating results with previous/next buttons.
 * Calls callback function when result is clicked to handle selection.
 * Provides buttons to dismiss popup without selection.
 * TODO: Add keyboard navigation
 * TODO: Fix and implement up down buttons
 * TODO: Get Select Button Working
 */
export function MapSearchBarResults(props){
    return (
        <div className={Style.rectangleDiv}>
            {
                props.results.map((result, index) => {
                return (
                    <div className={Style.groupDiv} key={index} onClick={(e)=> props.callback(result, e)}>
                        <div className={Style.autoAddedFrame}>
                            <div className={Style.rowItem}>
                                <img className={Style.icon1} alt="" src={iconMap[result.place_type[0]]}/>
                                <div className={Style.resultLabel1}>{result.place_name}</div>
                            </div>
                        </div>
                        <div className={Style.lineDivider}/>
                    </div>
                );
                })
            }
            <div className={Style.rectangleDiv2}>
                <div className={Style.controlsHolder}>
                    <div className={Style.leftIconHolder}>
                        <img className={Style.leftIcon} alt="" src={LeftIcon}/>
                    </div>
                    <div className={Style.rightIconHolder}>
                        <img className={Style.rightIcon} alt="" src={RightIcon}/>
                    </div>
                    <div className={Style.navText1}>To Navigate</div>
                    <div className={Style.selectIconHolder}>
                        <img className={Style.selectIcon} alt="" src={SelectIcon}/>
                    </div>
                    <div className={Style.navText1}>To Select</div>
                    {/*Escape Icon:*/}
                    <div className={Style.selectIconHolder}>
                        <img className={Style.selectIcon} alt="" src={EscapeIcon}/>
                    </div>
                    <div className={Style.navText1}>To Dismiss</div>
                </div>
            </div>
        </div>
    );
}