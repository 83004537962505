import React from 'react';
import { IconContext } from "react-icons";
import Style from "./back_banner.module.scss";
import {BiArrowBack} from "react-icons/bi";

export function BackButton(props){

    return(
        <div
            className={Style.back_row}
        >
            <IconContext.Provider value={{ className: Style.back_btn, size:'2.5em' }}>
                <div onClick={ () => props.callback(null) }>
                    <BiArrowBack/>
                </div>
            </IconContext.Provider>
        </div>
    );
}