/***
 *
 *   Search Bar
 *   Map Search input field
 *
 *   PROPS
 *   callback: function executed on change and submit
 *
 **********/

import React, { Fragment, useEffect } from 'react';
import styles from './map_search_bar.module.scss';
import searchIcon from './icons/Icon.svg';
import * as maptilerClient from '@maptiler/client';
import { MapSearchBarResults } from "./map_search_bar_results/map_search_bar_results";

maptilerClient.config.apiKey = "phrAxiNzhQ9QTc748JsR";


export function MapSearchBar(props) {
    const [searchString, setSearchString] = React.useState('');
    const [searchResults, setSearchResults] = React.useState([]);
    const [showResults, setShowResults] = React.useState(false);

    const hideResults = () => {
        setShowResults(false);
        console.log('hide results');
    }

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Escape') {
                event.preventDefault();
                hideResults();
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        // 👇️ clean up event listener
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    // const [value, setValue] = useState(props.value || '');
    // const css = ClassNames([ Style.search, props.className ]);

    const handleSearch = async (e) => {
        setSearchString(e);
        if (e.length > 2) {
            //FIXME: allow city search
            const results = await maptilerClient.geocoding.forward(e, { limit: 5, autocomplete: true, types: ["place", "address", "municipality", "joint_municipality", "locality"], proximity: [props.longitude, props.latitude] });
            setSearchResults(results.features);
            setShowResults(true);
        }
    }

    const handleResultClick = (result, e) => {
        props.markerCB(result);
        setSearchString(result.place_name);
        hideResults();
        // NOTE: this may need to be adjusted in the future if we need this event downstream
        e.stopPropagation();  // stop bubbling up to parent which persists the reach results holder, onFocusClick
    }

    const onFocusClick = () => {
        if (searchString.length >= 3) {
            setShowResults(true);
        }
    }

    return (
        <Fragment>
            <div className={styles.rectangleParent} onClick={onFocusClick}>
                <div className={styles.groupChild}>
                    <div className={styles.autoAddedFrame}>
                        <div className={styles.addon}>
                            <img className={styles.icon1} alt="" src={searchIcon} />
                            <div className={styles.addons}>Addons</div>
                        </div>
                    </div>
                    <div className={styles.inputfieldtext}>
                        <input className={styles.typeLocationHere1}
                            placeholder="Type location here..."
                            type="text"
                            value={searchString}
                            onChange={e => handleSearch(e.target.value)}
                        />
                        <div className={styles.minwidth}>
                            <div className={styles.content1} />
                        </div>
                    </div>
                </div>
                {/*<div className={styles.groupChild1}>*/}
                {/*</div>*/}
                {showResults &&
                    <MapSearchBarResults results={searchResults} callback={handleResultClick} />
                }
            </div>
        </Fragment>
        // <form className={ css }>
        //   <input
        //     type='text'
        //     className={ InputStyle.textbox }
        //     placeholder='Search'
        //     value={ value }
        //     onChange={ e => {
        //
        //       setValue(e.target.value);
        //       props.callback(e.target.value);
        //
        //     }}
        //   />
        //   <input
        //     type='submit'
        //     value=''
        //     className={ Style.btn }
        //     onClick={ e => {
        //
        //       e.preventDefault();
        //       props.callback(value);
        //
        //     }}
        //   />
        // </form>
    );
}
