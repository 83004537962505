/***
 *
 *   useAPI hook
 *   Interact with API calls – handle errors, return loading state and data
 *
 *   PROPS
 *   url: endpoint url
 *   method: get, post, put etc.. (default: get)
 *
 **********/

import { useState, useEffect, useContext, useCallback, useRef } from "react";
import Axios from "axios";
import { ViewContext } from "components/lib";

// TODO: check caching between routes
export function useAPI(url, method, data) {
  // context & state
  const context = useRef(useContext(ViewContext));
  const [state, setState] = useState({ data: null, loading: false, error: null });

  const fetch = useCallback(async () => {
    try {
      if (!url) {
        setState({ data: null, loading: false, error: null });
        return false;
      }

      setState({ loading: true });
      let res;
      if (data) {
        res = await Axios({
          url: url,
          method: method || "get",
          data: data,
        });
      } else {
        res = await Axios({
          url: url,
          method: method || "get",
        });
      }
      setState({ data: res.data.data || res.data.report, loading: false, error: null });
    } catch (err) {
      context?.current && context.current.handleError(err);
      setState({ data: null, loading: false, error: err });
    }
  }, [url, method, context, data]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return state;
}
