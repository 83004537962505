import { useEffect, useRef } from "react";
import Axios from "axios";

export async function fetchData(setData) {
    try {
        const res = await Axios({
            url: '/api/projects/sage/list',
            method: 'get',
        });
        setData({ data: res?.data?.data, loading: false });
    } catch (error) {
        console.error('Polling error:', error);
        setData({ data: null, loading: false });
    }
}

export default function usePollingEffect(callback, dependencies, { interval, onCleanUp }) {
    useEffect(() => {
        let isCancelled = false;

        const poll = async () => {
            await callback();
            if (!isCancelled) {
                setTimeout(poll, interval);
            }
        };

        poll();

        return () => {
            isCancelled = true;
            if (onCleanUp) {
                onCleanUp();
            }
        };
    }, dependencies);
}
