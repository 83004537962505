/**
 * ReportStepGeneralInfo Component
 * 
 * Captures general information for the report generation process, including the requesting entity, entity type, number of assets, and asset type.
 * Allows users to switch between different assessment types (e.g., GHG Emissions, Climate Hazards).
 * 
 * PROPS:
 * - formData (object): Contains the data entered by the user in previous steps.
 * - setFormData (function): Updates the form data with user input.
 * - nextStep (function): Moves to the next step in the process.
 * 
 * STATE:
 * - selectedAssessment (string): Tracks the selected assessment type (GHG or Climate Hazards).
 * 
 * FUNCTIONALITY:
 * - handleChange: Updates the form data when the user changes the input fields.
 * - handleSelect: Updates the selected assessment type when the user chooses a different option.
 */

import React, { useState } from 'react';
import { FaCaretDown } from "react-icons/fa";
import styles from './ReportStepGeneralInfo.module.scss';
import ButtonGroup from 'components/button_group/ButtonGroup';

function ReportStepGeneralInfo({ formData, setFormData, nextStep, errors }) {
    const [selectedAssessment, setSelectedAssessment] = useState('ghg');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const options = [
        { value: 'ghg', label: 'Goes to SAGE Report' },
        { value: 'climate', label: 'Create REST Report' }
    ];

    const handleSelect = (value) => {
        setSelectedAssessment(value);
    };

    return (
        <div className={styles.reportStep}>
            <div className={styles.tabRow}>
                <ButtonGroup
                    options={options}
                    selectedValue={selectedAssessment}
                    onSelect={handleSelect}
                />
            </div>
            <div className={styles.inputRow}>
                <div className={styles.inputGroup}>
                    <label>Requesting Entity</label>
                    <input
                        type="text"
                        name="requestingEntity"
                        placeholder="Enter Requestor Name"
                        value={formData.requestingEntity}
                        onChange={handleChange}
                        className={styles.textInput}
                    />
                    {errors.requestingEntity && <span className="errorText">{errors.requestingEntity}</span>}
                </div>
                <div className={styles.inputGroup}>
                    <label>Entity Type</label>
                    <div className={styles.selectWrapper}>
                        <select
                            name="entityType"
                            value={formData.entityType}
                            onChange={handleChange}
                            className={styles.selectInput}
                        >
                            <option value="" disabled>
                                Who are you?
                            </option>
                            <option value="corporate">Corporate</option>
                            <option value="individual">Individual</option>
                        </select>
                        <FaCaretDown className={styles.selectIcon} />
                    </div>
                    {errors.entityType && <span className="errorText">{errors.entityType}</span>}
                </div>
            </div>
            <div className={styles.inputRow}>
                <div className={styles.inputGroup}>
                    <label># of Assets (locations)</label>
                    <input
                        type="number"
                        name="numberOfAssets"
                        placeholder="Enter No of Assets"
                        value={formData.numberOfAssets}
                        onChange={handleChange}
                        className={styles.textInput}
                    />
                    {errors.numberOfAssets && <span className="errorText">{errors.numberOfAssets}</span>}
                </div>
                <div className={styles.inputGroup}>
                    <label>Assets Type</label>
                    <div className={styles.selectWrapper}>
                        <select
                            name="assetType"
                            value={formData.assetType}
                            onChange={handleChange}
                            className={styles.selectInput}
                        >
                            <option value="" disabled>
                                What is assets type?
                            </option>
                            <option value="business">Business</option>
                            <option value="home">Home</option>
                            <option value="other">Other</option>
                        </select>
                        <FaCaretDown className={styles.selectIcon} />
                    </div>
                    {errors.assetType && <span className="errorText">{errors.assetType}</span>}
                </div>
            </div>
        </div>
    );
}

export default ReportStepGeneralInfo;
