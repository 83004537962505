/***
 *
 *   Address Page
 *
 **********/

import React, {useState} from 'react';
import {Animate, Feedback, useAPI, Loader, Card, Row, ProjectStepsHolder, Button} from 'components/lib';
import {useParams} from "react-router-dom";
import usePollingEffect from "../../components/hooks/polling";
import Axios from "axios";
import {BackButton} from "../../components/nav/back_banner/back_banner";
import {FullTableauDashboard} from "../../components/tableau_wrapper/full_report";
import {BsArrowBarRight, BsCheck2Circle, BsSearch} from "react-icons/bs";
import {HiOutlineInboxArrowDown} from "react-icons/hi2";
import {VscRadioTower} from "react-icons/vsc";
import {MdOutlinePower} from "react-icons/md";


const options = {
    'Submitted': { name: 'Submitted', url:"", percent:5, icon:BsArrowBarRight },
    'Received': { name: "Received", url:"", percent:25, icon: HiOutlineInboxArrowDown },
    'Working on Scope 1': { name: "Working on Scope 1", url:"", percent:40, icon: VscRadioTower },
    'Working on Scope 2': { name: "Working on Scope 2", url:"", percent:60, icon: MdOutlinePower },
    'Quality Checks': { name: "Quality Checks", url:"", percent:80, icon: BsSearch },
    'Complete': { name: "Complete", url:"", percent:100, icon: BsCheck2Circle}
}

export function SageProjectLanding(props) {
    const { project } = useParams();
    const project_data = useAPI('/api/projects/sage/' + project);
    const [ status, setStatus ] = useState();
    const [ projectName, setProjectName ] = useState();
    const [showTableauReport, setShowTableauReport] = useState(false);
    let statusIndex = 0;

    usePollingEffect(
        async () => {
            if(status === "Complete")
                return;  // Don't keep polling if the project is complete
            const res = await Axios({
                url: '/api/projects/sage/' + project,
                method: 'get',
            });
            let p = res.data.data;
            console.log(statusIndex);
            setStatus(p.project_status);
            setProjectName(p.project_name);
                if(status !== undefined){
                    statusIndex = Object.keys(options).indexOf(status);
                    if(status !== 'Complete'){
                        statusIndex += 1;
                    }
                }
        },
        [],
        {
            interval: 10000, onCleanUp: () => {
            }
        } // optional
    );

    if (project_data.loading)
        return <Loader />

    if (showTableauReport)
        return (
            <>
                <BackButton
                    callback={e => setShowTableauReport(false)}
                />
                <FullTableauDashboard
                    project={project}
                />
            </>
        );

    return (
        <Animate type='pop'>


            <>
                <BackButton
                    callback={e => window.location='../'}
                />
            </>

            <Card
                title={'SAGE - ' + projectName + ' - Project Tracker & Deliverables'}
                center
            >
            <ProjectStepsHolder
                options={options}
                projectName={projectName}
                status={status}
            />
                {
                status === 'Complete' &&
                <Row
                >
                    <Button
                        text='Download Excel Data'
                        color={'gray'}
                        disabled
                        small
                    />
                    <Button
                        text='View Report'
                        color={'blue'}
                        small
                        action={ e => setShowTableauReport(true) }
                    />
                </Row>
                }
            </Card>
            <Feedback/>
        </Animate>
    );
}
