/***
*
*   ICON
*   Render an icon from React icons
*
*   PROPS
*   color: dark/light or hex code
*   className: inject a parent class object
*
**********/

import React, {Fragment} from 'react';
import ClassNames from 'classnames';
import Style from './icon.module.scss';

export function RIcon(props){
  const Icon = props.icon;
  const css = ClassNames([
    Style.icon,
    props.className
  ]);

  return(
      <Fragment>
        <Icon
            className={css}
        />
      </Fragment>
  )
}
