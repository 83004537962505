export const calculateTotalPrice = (selectedItems, itemPrices) => {
    return Object.entries(selectedItems).reduce((total, [item, isSelected]) => {
      return isSelected ? total + (itemPrices[item] || 0) : total;
    }, 0);
  };
  
  export const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(price);
  };
  
  export const calculatePricePerAsset = (totalPrice, assetCount) => {
    return assetCount > 0 ? totalPrice / assetCount : 0;
  };