/**
 * ReportModalHeader Component
 * 
 * This component renders the header of the modal, including the title, subtitle, and progress indicator.
 * It also provides a close button for dismissing the modal.
 * 
 * PROPS:
 * - step (number): Current step in the modal process.
 * - title (string): Title of the modal, which changes based on the step.
 * - subtitle (string): Subtitle displayed under the title.
 * - onClose (function): Function to close the modal.
 * 
 * USAGE:
 * Displays the current step's title, subtitle, a progress indicator, and a close button for the modal.
 */

import React from 'react';
import { IoCloseOutline } from "react-icons/io5";
import ProgressIndicator from "./steps/ProgressIndicator"


function ReportModalHeader({ step, title, subtitle, onClose }) {
    return (
        <div className="modalHeader">
            <div className='modalHeader_content'>
                <div className='modalHeader_ttls'>
                    <h2 className='title'>{step < 3 ? title : 'Order Form'}</h2>
                    <p className="subtitle">{subtitle}</p>
                </div>
                <ProgressIndicator step={step} />
            </div>
            <button className="closeButton" onClick={onClose}><IoCloseOutline className='closeButton_icon' /></button>
        </div>
    );
}

export default ReportModalHeader;
