import React, { useContext, useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { AuthContext, Loader } from "../../lib";
import { StripeWrapperPaymentForm } from "../stripe_wrapper/stripe_wrapper";
import Axios from "axios";
import { jwtDecode } from "jwt-decode";

const Settings = require("settings.json");
const StripePromise = loadStripe(
  Settings[process.env.NODE_ENV].stripe.publishableAPIKey
);

export function CheckoutForm({ numOfCredits = 0 }) {
  const context = useContext(AuthContext);

  const [clientSecret, setClientSecret] = useState("");
  const [clientSecretLoaded, setClientSecretLoaded] = useState(false);
  // causing infinite loop with 'post'
  // const clientSecretData = useAPI("/api/purchase_intent", 'post', {items: [{id: "xl-tshirt"}]})

  const fetchPurchaseIntent = async () => {
    const userData = jwtDecode(context.user.token);

    if (!userData?.userId) {
      throw Error("No user id for purchase intent");
    }

    const purchaseIntent = await Axios({
      url: "/api/purchase_intent",
      method: "post",
      data: { userId: userData.userId, creditsToBePurchased: numOfCredits },
    });

    // console.info("fetchPurchaseIntent", purchaseIntent);

    setClientSecret(purchaseIntent.data.data.clientSecret);
    setClientSecretLoaded(true);
  };

  useEffect(() => {
    fetchPurchaseIntent();
  }, []);

  if (!clientSecretLoaded) return <Loader />;

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <Elements stripe={StripePromise} options={options}>
      {clientSecret && <StripeWrapperPaymentForm clientSecret={clientSecret} />}
    </Elements>
  );
}
