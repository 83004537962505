/***
*
*   FOOTER (homepage)
*   Static homepage footer
*
**********/

import React from 'react';
import Style from './footer.module.scss';

export function Footer(props){

  return (
      <footer className={ Style.footer }>
          <div className={Style.contentWrapper}>
            <span>{ `Copyright © Floodlight ${new Date().getFullYear()}`}</span>
          </div>
      </footer>
  );
}
