/***
*
*   PROGRESS STEPS
*   Steps are used to indicate the current point in a
*   multi-stage process, such as filling in a long form
*
*   PROPS
*   items: array of objects containing keys: name, url
*   and completed (bool)
*
**********/

import React from 'react';
import "react-step-progress-bar/styles.css";
import Style from './steps.module.scss';
import { RIcon } from "../../lib";
import ClassNames from "classnames";

export function MyStep(props){
    const name = props.name;
    const css = ClassNames([
        Style.step,
        props.className,
        props.accomplished && Style.accomplished,
    ]);
    const active = props.isActive;

  return(
      <div
          style={props.style}
          className={css}>
          <div
            className={ClassNames([Style.dot, props.accomplished ? Style.accomplished_dot : '', active ? Style.in_progress : ''])}>
              <div
                  className={Style.step_icon}
                  >
                  <RIcon
                      color='light'
                      size={20}
                      icon={ props.icon }
                  />
              </div>
              <div
              className={Style.label}>
                  <div>{name}</div>
              </div>
          </div>
      </div>
  )
}
