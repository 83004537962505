/**
 * ReportModalBody Component
 * 
 * This component renders the content of the modal dynamically based on the current step.
 * It includes various step components for inputting different types of information during the report generation process.
 * 
 * PROPS:
 * - step (number): Current step in the modal process.
 * - formData (object): Data collected from previous steps, which gets passed to step components.
 * - setFormData (function): Updates the formData state.
 * - nextStep (function): Moves to the next step.
 * - prevStep (function): Moves to the previous step.
 * 
 * USAGE:
 * This component conditionally renders different step components based on the current step (1-4).
 */

import React, { useRef, useEffect } from 'react';
import ReportStepGeneralInfo from "./steps/ReportStepGeneralInfo"
import ReportStepAssetDetails from './steps/ReportStepAssetDetails';
import ReportStepUseCaseSelection from './steps/ReportStepUseCaseSelection';
import ReportStepCheckOut from './steps/ReportStepCheckOut';

const ReportModalBody = ({ step, formData, setFormData, nextStep, prevStep, errors, submitForm }) => {
    const modalBodyRef = useRef(null);

    useEffect(() => {
        if (modalBodyRef.current) {
            modalBodyRef.current.scrollTop = 0;
        }
    }, [step]);
    return (
        <div className="modalBody" ref={modalBodyRef}>
            {step === 1 && (
                <ReportStepGeneralInfo
                    formData={formData}
                    setFormData={setFormData}
                    nextStep={nextStep}
                    errors={errors}
                />
            )}
            {step === 2 && (
                <ReportStepAssetDetails
                    formData={formData}
                    setFormData={setFormData}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    errors={errors}
                />
            )}
            {step === 3 && (
                <ReportStepUseCaseSelection
                    formData={formData}
                    setFormData={setFormData}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    errors={errors}
                />
            )}
            {step === 4 && (
                <ReportStepCheckOut
                    formData={formData}
                    prevStep={prevStep}
                    nextStep={nextStep}
                    submitForm={submitForm}
                />
            )}
        </div>
    );
}

export default ReportModalBody;
