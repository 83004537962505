/***
 *
 *   HOME NAV
 *   Navigation used on the main external website. Renders a dashboard link
 *   if the user is signed in, or a sign up link if they are not
 *
 **********/

import React, { Fragment, useContext } from 'react';
import ClassNames from 'classnames';
import { AuthContext, Logo, Link } from 'components/lib';
import Style from './home.module.scss';

export function HomeNav(props) {

    // context
    const context = useContext(AuthContext);

    // style
    const css = ClassNames([

        Style.nav,
        props.transparent && Style.transparent

    ]);

    return (
        <section className={css}>
            <div>
                <Logo className={Style.logo} path={"https://floodlightinvest.com"} />
                <nav className={Style.navStyle}>
                    <div className={Style.linksHolder}>
                        {context.user?.token ?
                            <Link
                                btn
                                url='/dashboard'
                                className={Style.cta}
                                text='Dashboard'
                            /> :
                            <Fragment>
                                <Link
                                    link
                                    url='/sign_in'
                                    text='Sign In' />
                                <Link
                                    btn
                                    url='/sign_up'
                                    className={Style.cta}
                                    text='Sign Up'
                                />
                            </Fragment>
                        }
                    </div>
                </nav>
            </div>
        </section>
    );
}
