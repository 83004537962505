/**
 * Validation Functions
 * 
 * These functions are responsible for validating user input in the report generation process. Each function checks a specific form field and returns an error message if the input is invalid.
 * 
 * FUNCTION: validateRequestingEntity
 * Validates the requesting entity field to ensure it is not empty or whitespace.
 * 
 * PARAMS:
 * - value (string): The input value for the requesting entity field.
 * 
 * RETURNS:
 * - (string|null): Returns an error message if the field is invalid, otherwise returns null.
 * 
 * FUNCTION: validateEntityType
 * Validates the entity type field to ensure an entity type is selected.
 * 
 * PARAMS:
 * - value (string): The input value for the entity type field.
 * 
 * RETURNS:
 * - (string|null): Returns an error message if the field is invalid, otherwise returns null.
 * 
 * FUNCTION: validateNumberOfAssets
 * Validates the number of assets field to ensure it is a positive number.
 * 
 * PARAMS:
 * - value (string|number): The input value for the number of assets field.
 * 
 * RETURNS:
 * - (string|null): Returns an error message if the field is invalid (e.g., non-numeric or less than or equal to zero), otherwise returns null.
 * 
 * FUNCTION: validateAssetType
 * Validates the asset type field to ensure an asset type is selected.
 * 
 * PARAMS:
 * - value (string): The input value for the asset type field.
 * 
 * RETURNS:
 * - (string|null): Returns an error message if the field is invalid, otherwise returns null.
 */

export const validateRequestingEntity = (value) => {
    if (!value || value.trim() === '') {
        return "Requesting entity is required.";
    }
    return null;
};

export const validateEntityType = (value) => {
    if (!value) {
        return "Please select an entity type.";
    }
    return null;
};

export const validateNumberOfAssets = (value) => {
    if (!value || isNaN(value) || value <= 0) {
        return "Number of assets must be a positive number.";
    }
    return null;
};

export const validateAssetType = (value) => {
    if (!value) {
        return "Please select an asset type.";
    }
    return null;
};
