/***
*
*   MESSAGE
*   Colored feedback message with optional call to action
*
*   PROPS
*   type - info/success/warning/error
*   title - descriptive string
*   text - string
*   closable - boolean to determine if the user can close the message
*   buttonText - text for the cta button (optional)
*   buttonLink - url link for the button (optional)
*
**********/

import React, { useState } from 'react';
import ClassNames from 'classnames'
import { Button, Icon, History } from 'components/lib';
import Style from './message.module.scss';

export function Message(props){

  // state
  const [closed, setClosed] = useState(false);
  const type = props.type || 'info';

  if (closed)
    return false;

  const icon = {

    info: 'info',
    success: 'check',
    warning: 'alert-triangle',
    error: 'alert-octagon'

  };

  const color = {

    info: '#73B0F4',
    success: '#8CC57D',
    warning: '#F0AA61',
    error: '#d95565'

  }

  // style
  const css = ClassNames([

    Style.message,
    Style[type]

  ]);

  return (
    <div className= { css }>

      <Icon
        className={ Style.icon }
        color={ color[type] }
        image={ icon[type] }
      />

      { props.closable &&
        <Button
          icon='x'
          size={ 20 }
          className={ Style.close }
          action={ e => setClosed(true) }
        />
      }

      <section className={ Style.content }>

        { props.title && <h1>{ props.title }</h1> }
        { props.text && <p>{ props.text }</p> }

        { props.buttonLink &&
          <Button
            className={ Style.btn }
            text={ props.buttonText }
            action={ e => History.push(props.buttonLink) }
            pullRight={ !!props.buttonPullRight}
          />
        }
        { props.children }

     </section>
    </div>
  );
}
