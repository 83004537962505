import { atom } from "jotai";

export const currentAddressAtom = atom("");

export const scope1YearlyCellAtom = atom([
  { year: "2020", value: 3 },
  { year: "2021", value: 4 },
  { year: "2022", value: 5 },
]);

export const scope1Atom = atom((get) => {
  const res = get(scope1YearlyCellAtom).filter((item) => item.year === "2022");
  console.log(res);
  return res.length ? res[0].value : 0;
});

export const scope1SquareFootageAtom = atom(2); // Square Footage of the building

export const scope1IntensityAtom = atom((get) =>
  get(scope1YearlyCellAtom).map((item) => {
    return {
      year: item.year,
      value: item.value / get(scope1SquareFootageAtom),
    };
  })
);

export const landSumAtom = atom(1);

export const ratings = [
  {
    id: 1,
    name: "Low",
    color: "#7FAE17",
  },
  {
    id: 2,
    name: "Moderately low",
    color: "#BDDC78",
  },
  {
    id: 3,
    name: "Very low",
    color: "#7FAE17",
  },
  {
    id: 4,
    name: "High",
    color: "#EF5B47",
  },
  {
    id: 5,
    name: "Moderate",
    color: "#EF5B47",
  },
  {
    id: 6,
    name: "Moderately high",
    color: "#EF5B47",
  },
  {
    id: 7,
    name: "Very high",
    color: "#EF5B47",
  },
];

export const scope2RiskAtom = atom([
  {
    id: 1,
    name: "Earthquake Risk",
    value: 38.2,
    rating: 1,
  },
  {
    id: 2,
    name: "Hail Risk",
    value: 12.3,
    rating: 3,
  },
  {
    id: 3,
    name: "Coastal Flooding",
    value: 26.5,
    rating: 2,
  },
  {
    id: 4,
    name: "River Flooding",
    value: 34.2,
    rating: 1,
  },
  {
    id: 5,
    name: "Wind Events Risk",
    value: 22.4,
    rating: 1,
  },
  {
    id: 6,
    name: "Hurricane Risk",
    value: 73.1,
    rating: 2,
  },
  {
    id: 7,
    name: "Tornado Risk",
    value: 2.4,
    rating: 3,
  },
  {
    id: 8,
    name: "Tsunami Risk",
    value: 27.7,
    rating: 4,
  },
  {
    id: 9,
    name: "Heat Wave Risk",
    value: 55.3,
    rating: 5,
  },
  {
    id: 10,
    name: "Cold Wave Risk",
    value: 18.7,
    rating: 1,
  },
  {
    id: 11,
    name: "Drought Risk",
    value: 0,
    rating: 3,
  },
  {
    id: 12,
    name: "Wildfire Risk",
    value: 40,
    rating: 2,
  },
  {
    id: 13,
    name: "Ice Storm Risk",
    value: 30.6,
    rating: 1,
  },
  {
    id: 14,
    name: "Intense Snow Risk",
    value: 36.5,
    rating: 2,
  },
]);
