/***
 *
 *   BILLING
 *   Change a subscription plan or update card details
 *
 **********/

import React, { Fragment, useContext } from "react";
import {
  AuthContext,
  Card,
  AccountNav,
  TabView,
  Message,
  Form,
  PaymentForm,
  Animate,
  useAPI,
  usePlans,
} from "components/lib";
import { Box } from "components/box/box";

const Messages = require("./messages.json");

export function Billing(props) {
  // state/context
  const context = useContext(AuthContext);

  // fetch plans and subscription
  const plans = usePlans();
  const subscription = useAPI("/api/account/subscription");
  const isPaid = context.user.plan === "free" ? false : true;

  return (
    <Fragment>
      <AccountNav />
      <Animate>
        <Box paddingX={1}>
          {!subscription?.loading && subscription?.data !== "active" && (
            <Message {...Messages[subscription.data]} />
          )}

          <TabView
            name="Billing"
            labels={["Plan", isPaid ? "Card" : undefined]}
          >
            <Card loading={plans.loading} restrictWidth>
              <Form
                data={{
                  plan: {
                    label: "Your subscription plan",
                    type: "select",
                    required: true,
                    default: plans?.data?.active,
                    options: plans?.data?.list,
                  },
                }}
                url="/api/account/plan"
                method="PATCH"
                buttonText="Update Plan"
                callback={(res) => {
                  context.update({ plan: res.data.data.plan });
                }}
              />
            </Card>

            {isPaid && (
              <Card restrictWidth>
                <PaymentForm
                  className="restrict-width"
                  data={{
                    token: {
                      label: "Update Your Card",
                      type: "creditcard",
                      required: true,
                    },
                  }}
                  url="/api/account/card"
                  method="PATCH"
                  buttonText="Save Card"
                />
              </Card>
            )}
          </TabView>
        </Box>
      </Animate>
    </Fragment>
  );
}
