/**
 * ProgressIndicator Component
 * 
 * This component visually represents the current step of a multi-step process.
 * It dynamically shows the current step with an icon and label, highlighting the active step.
 * 
 * PROPS:
 * - step (number): The current active step in the progress indicator.
 * 
 * Renders a step for each item in the `steps` array, indicating which step is active.
 */

import React from 'react';
import { FaFileAlt, FaMapMarkerAlt, FaClipboardList, FaCreditCard } from 'react-icons/fa';
import styles from './ProgressIndicator.module.scss';

function ProgressIndicator({ step }) {
    const totalSteps = 4;
    const steps = [
        { icon: FaFileAlt },
        { icon: FaMapMarkerAlt },
        { icon: FaClipboardList },
        { icon: FaCreditCard }
    ];

    return (
        <div className={styles['progress-indicator']}>
            {steps.map((s, index) => (
                <div key={index} className={`${styles['progress-indicator__step']} ${step === index + 1 ? styles['active'] : ''}`}>
                    <div className={styles['progress-indicator__icon-wrapper']}>
                        <s.icon className={`${styles['progress-indicator__icon']} ${step === index + 1 ? styles['active'] : ''}`} />
                    </div>
                    {step === index + 1 && (
                        <div className={styles['progress-indicator__step-text']}>
                            <span>Step {index + 1}/{totalSteps}</span>
                        </div>
                    )}
                    <div className={styles['progress-indicator__divider']} />
                </div>
            ))}
        </div>
    );
}

export default ProgressIndicator;
