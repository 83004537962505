/**
 * ReportModal Component
 * 
 * This component is responsible for displaying an overlay modal, which can 
 * be used to show different types of content, such as a form, a message, 
 * or any custom content passed via props.children. It leverages the 
 * CSSTransition component for a smooth appearance and disappearance effect.
 * 
 * PROPS:
 * - title (string, optional): The title displayed at the top of the modal.
 * - text (string, optional): A message or description text to be displayed in the modal.
 * - form (object, optional): If provided, the modal will render a form component with the specified method, URL, and data.
 * - method (string, optional): HTTP method for form submission (e.g., 'POST', 'GET').
 * - url (string, optional): URL to which the form data will be submitted.
 * - buttonText (string, optional): Text displayed on the form submit button.
 * - children (ReactNode, optional): Additional content or components that can be rendered inside the modal.
 * 
 * CONTEXT:
 * This component relies on the ViewContext for managing modal visibility (show/hide logic).
 * 
 */

import React, { useContext } from 'react';
import { ViewContext, Form } from 'components/lib';
import { CSSTransition } from 'react-transition-group';
import './reportmodal.scss';

export function ReportModal(props) {
    const context = useContext(ViewContext);

    return (
        <CSSTransition in appear timeout={0} classNames="modal">
            <div className="report-modal__overlay" onClick={(e) => e.target === e.currentTarget && context.modal.hide(true)}>
                <div className="report-modal__content">
                    {props.title && (
                        <header className="report-modal__header">
                            <h1>{props.title}</h1>
                        </header>
                    )}

                    {props.text && (
                        <p className="report-modal__text">{props.text}</p>
                    )}

                    {props.form && (
                        <Form
                            method={props.method}
                            url={props.url}
                            data={props.form}
                            buttonText={props.buttonText}
                            cancel={e => context.modal.hide(true)}
                        />
                    )}

                    {props.children}
                </div>
            </div>
        </CSSTransition>
    );
}
