/***
 *
 *   IMAGE
 *   Import the image before passing it to this component
 *
 *   PROPS
 *   source: imported source
 *   alt: alt description
 *   title: description
 *   className: inject a custom class object
 *
 **********/

import React from 'react';
import Style from './image.module.scss';
import ClassNames from "classnames";

export function Image(props) {


    const css = ClassNames([

        Style.image,
        props.className

    ]);

    return (
        <img
            style={{width:props.size}}
            src={props.source}
            alt={props.alt}
            title={props.title}
            className={css}
        />
    );
}
