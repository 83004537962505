/***
 *
 *   AUTH LAYOUT
 *   Layout for the signup/signin pages
 *
 **********/

import React from "react";
import { HomeNav, Footer } from "components/lib";
import Style from "./auth.module.scss";

export function AuthLayout(props) {
  return (
    <main className={Style.auth}>
      <HomeNav transparent={true} />
      {<props.children {...props.data} />}
      <Footer />
    </main>
  );
}
