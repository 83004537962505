import React, { Fragment, useState } from 'react';
import { Dropzone, FileMosaic } from "@dropzone-ui/react";



export function FileDropzone(props) {
  const [files, setFiles] = useState([]);
  const updateFiles = (incomingFiles) => {
    setFiles(incomingFiles);
    props.fileChangeCB(incomingFiles);
  };

  return (
    <Fragment>
      <Dropzone onChange={updateFiles} value={files}>
        {files.map((file) => (
          <FileMosaic key={files.indexOf(file)} {...file} preview />
        ))}
      </Dropzone>
    </Fragment>
  );
}
