import React from 'react';
import styles from './ButtonGroup.module.scss';

function ButtonGroup({ options, selectedValue, onSelect }) {
    return (
        <div className={styles.buttonGroup}>
            {options.map(option => (
                <button
                    key={option.value}
                    className={`${styles.button} ${selectedValue === option.value ? styles.active : ''}`}
                    onClick={() => onSelect(option.value)}
                >
                    {option.label}
                </button>
            ))}
        </div>
    );
}

export default ButtonGroup;
