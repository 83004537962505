import { Home } from 'views/website/home';
import { Contact } from 'views/website/contact';
import { Terms } from 'views/website/terms';
import { Privacy } from 'views/website/privacy';

const website =
  [
    {
      path: '/',
      view: Home,
      layout: 'home',
      title: 'Floodlight Data Platform'
    },
    {
      path: '/contact',
      view: Contact,
      layout: 'home',
      title: 'Contact'
    },
    {
      path: '/privacy',
      view: Privacy,
      layout: 'home',
      title: 'Privacy Policy'
    },
    {
      path: '/terms',
      view: Terms,
      layout: 'home',
      title: 'Floodlight Terms & Conditions'
    },
  ]

export default website