/**
 * ReportStepAssetDetails Component
 * 
 * Allows users to enter asset details manually or upload files containing asset data (e.g., shapefiles, coordinates, addresses).
 * 
 * PROPS:
 * - formData (object): Data entered by the user in previous steps.
 * - setFormData (function): Updates the form data with new asset details.
 * - nextStep (function): Moves to the next step in the report generation process.
 * - prevStep (function): Moves to the previous step in the process.
 * - errors (object): Validation errors for the asset data.
 * 
 * STATE:
 * - currentTab (string): Tracks which tab is active (Shapefiles, Coordinates, Addresses).
 * - shapefilesData, coordinatesData, addressesData (arrays): Stores data for each tab.
 * - uploadedFile (object): Stores the uploaded Excel or shapefile.
 * 
 * FUNCTIONALITY:
 * - handleAssetChange, addNewAsset, removeAsset: Handle updates to the asset data.
 * - handleFileUpload: Handles file uploads for the current tab.
 * - readExcelFile: Reads and processes Excel files to extract asset data.
 */


import React, { useState, useEffect, useContext } from 'react';
import { HiPlus, HiTrash } from "react-icons/hi";
import * as XLSX from 'xlsx';
import ButtonGroup from 'components/button_group/ButtonGroup';
import { downloadExcelTemplate } from 'utils/excelGenerator';
import { ViewContext } from 'components/lib';
import styles from './ReportStepAssetDetails.module.scss';



function ReportStepAssetDetails({ formData, setFormData, nextStep, prevStep, errors }) {
    const [currentTab, setCurrentTab] = useState('coordinates');
    const [uploadedFile, setUploadedFile] = useState(null);

    const [shapefilesData, setShapefilesData] = useState([]);
    const [coordinatesData, setCoordinatesData] = useState([]);
    const [addressesData, setAddressesData] = useState([]);
    const { notification } = useContext(ViewContext);

    const tabOptions = [
        { value: 'shapefiles', label: 'Shapefiles' },
        { value: 'coordinates', label: 'Coordinates' },
        { value: 'addresses', label: 'Addresses' },
    ];

    useEffect(() => {
        const allAssets = [...shapefilesData, ...coordinatesData, ...addressesData];
        const assetCount = allAssets.length;
        const assetTypes = new Set(allAssets.map(asset => asset.type));
        const assetType = assetTypes.size === 1 ? Array.from(assetTypes)[0] : 'Mixed';

        setFormData(prevFormData => ({
            ...prevFormData,
            assets: allAssets,
            assetSummary: { count: assetCount, type: assetType },
        }));
    }, [shapefilesData, coordinatesData, addressesData, setFormData]);


    const getCurrentTabData = () => {
        if (currentTab === 'shapefiles') return shapefilesData;
        if (currentTab === 'coordinates') return coordinatesData;
        return addressesData;
    };


    const setCurrentTabData = (newData) => {
        if (currentTab === 'shapefiles') setShapefilesData(newData);
        if (currentTab === 'coordinates') setCoordinatesData(newData);
        if (currentTab === 'addresses') setAddressesData(newData);
    };

    const handleTabChange = (selectedTab) => setCurrentTab(selectedTab);

    const handleAssetChange = (index, field, value) => {
        const updatedAssets = getCurrentTabData().map((asset, i) =>
            i === index ? { ...asset, [field]: value } : asset
        );
        setCurrentTabData(updatedAssets);
    };

    const addNewAsset = () => {
        const newAsset = {
            name: '', type: '', latitude: '', longitude: '', owner: '', description: '', address: '', industry: ''
        };
        setCurrentTabData([...getCurrentTabData(), newAsset]);
    };

    const removeAsset = (index) => {
        const updatedAssets = getCurrentTabData().filter((_, i) => i !== index);
        setCurrentTabData(updatedAssets);
    };

    const handleFileUpload = async (event, index = null) => {
        const file = event.target.files[0];
        if (currentTab === 'shapefiles') {
            if (file && file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                try {
                    const jsonData = await readExcelFile(file);
                    const newShapefileData = jsonData.map(row => ({
                        name: row['Asset Name'] || '',
                        type: row['Asset Type'] || 'Shapefile',
                        file: file,
                        owner: row['Asset Owner'] || '',
                        description: row['Brief Description'] || '',
                        jsonData: row
                    }));

                    if (index !== null) {
                        const updatedShapefilesData = shapefilesData.map((asset, i) =>
                            i === index ? newShapefileData[0] : asset
                        );
                        setShapefilesData(updatedShapefilesData);
                    } else {
                        setShapefilesData([...shapefilesData, newShapefileData[0]]);
                    }
                } catch (error) {
                    console.error("Error reading shapefile:", error);
                    notification.show("Error reading shapefile. Please try again.", "error", true);
                }
            }
        } else if (['coordinates', 'addresses'].includes(currentTab)) {
            if (file && file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                try {
                    const jsonData = await readExcelFile(file);
                    processExcelData(jsonData);
                } catch (error) {
                    console.error("Error reading Excel file:", error);
                    notification.show("Error reading Excel file. Please try again.", "error", true);
                }
            } else {
                notification.show("Please upload a valid Excel file.", "error", true);
            }
        }
    };

    const readExcelFile = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet);
                    resolve(jsonData);
                } catch (error) {
                    reject(error);
                }
            };
            reader.onerror = (error) => reject(error);
            reader.readAsArrayBuffer(file);
        });
    };

    const processExcelData = (data) => {
        let updatedAssets = [];
        if (currentTab === 'addresses') {
            updatedAssets = data.map((row) => ({
                name: row['Asset Name'] || '',
                type: row['Industry'] || '',
                address: row['Address'] || '',
                owner: row['Asset Owner'] || '',
                description: row['Brief Description'] || ''
            }));
            setAddressesData(updatedAssets);
        } else if (currentTab === 'coordinates') {
            updatedAssets = data.map((row) => ({
                name: row['Asset Name'] || '',
                type: row['Asset Type'] || '',
                latitude: row['Latitudes'] || '',
                longitude: row['Longitude'] || '',
                owner: row['Asset Owner'] || '',
                description: row['Brief Description'] || ''
            }));
            setCoordinatesData(updatedAssets);
        }
    };

    const handleDownloadTemplate = () => {
        downloadExcelTemplate(currentTab);
    };

    return (
        <div className={styles['report-step']}>
            <ButtonGroup options={tabOptions} selectedValue={currentTab} onSelect={handleTabChange} />

            <div className={styles['asset-summary']}>
                <p>No of Assets: <span className={styles['asset-summary__count']}>{getCurrentTabData().length}</span></p> |
                <p>Assets Type: <span className={styles['asset-summary__type']}>{formData.assetSummary?.type || 'Mixed'}</span></p>
            </div>
            {errors.assets && <div className="errorText">{errors.assets}</div>}

            {currentTab !== 'shapefiles' && (
                <div className={styles['upload-section']}>
                    <p className={styles['upload-section__title']}>Upload file</p>
                    <p className={styles['upload-section__subtitle']}>
                        Upload a single excel file with all of the info.
                        <span
                            className={styles['upload-section__template-link']}
                            onClick={handleDownloadTemplate}
                        >
                            Download Template
                        </span>
                    </p>
                    <div className={styles['file-upload']}>
                        <label className={styles['file-upload__label']}>
                            <input
                                type="file"
                                className={styles['file-upload__input']}
                                onChange={handleFileUpload}
                            />
                            <HiPlus className={styles['file-upload__icon']} />
                            <span>Upload File</span>
                        </label>
                        {uploadedFile && <p>Uploaded: {uploadedFile.name}</p>}
                    </div>
                </div>
            )}

            {currentTab !== 'shapefiles' && (
                <div className={styles['divider']}>
                    <span>Or</span>
                </div>
            )}

            <div className={styles['asset-entry']}>
                <div>
                    <p className={styles['asset-entry__title']}>Data Entry</p>
                    <p className={styles['asset-entry__subtitle']}>Fill your location data in the table below.</p>
                </div>
                <button className={styles['asset-entry__add-button']} onClick={addNewAsset}>
                    <HiPlus className={styles['asset-entry__add-icon']} />
                    Add New Asset
                </button>
            </div>

            {currentTab === 'shapefiles' && (
                <DataTable
                    formData={{ assets: shapefilesData }}
                    handleFileUpload={handleFileUpload}
                    handleAssetChange={handleAssetChange}
                    removeAsset={removeAsset}
                    fileUploadLabel={styles['file-upload__label']}
                    fileInputClass={styles['file-upload__input']}
                    fileIconClass={styles['file-upload__icon']}
                />
            )}

            {currentTab === 'coordinates' && (
                <CoordinatesTable
                    formData={{ assets: coordinatesData }}
                    handleAssetChange={handleAssetChange}
                    removeAsset={removeAsset}
                />
            )}

            {currentTab === 'addresses' && (
                <AddressesTable
                    formData={{ assets: addressesData }}
                    handleAssetChange={handleAssetChange}
                    removeAsset={removeAsset}
                />
            )}

            {currentTab !== 'shapefiles' && (
                <div className={styles['note']}>
                    <p className={styles['note__title']}>Notes:</p>
                    <p className={styles['note__subtitle']}>
                        If asset location data is provided as geo-coordinates or addresses, Floodlight will determine asset boundaries using all available information...
                    </p>
                </div>
            )}
        </div>
    );
}


const DataTable = ({ formData, handleAssetChange, removeAsset, handleFileUpload, fileUploadLabel, fileInputClass, fileIconClass }) => (
    <div className={styles['data-entry']}>
        <table className={styles['data-table']}>
            <thead>
                <tr>
                    <th>No</th>
                    <th>Asset Name</th>
                    <th>Asset Type</th>
                    <th>Asset Owner</th>
                    <th>Brief Description</th>
                    <th>Upload Shapefiles</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody style={{ maxHeight: '400px', overflowY: 'auto' }}>
                {formData.assets.map((asset, index) => (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td><input type="text" value={asset.name} placeholder="Enter Asset name" onChange={(e) => handleAssetChange(index, 'name', e.target.value)} /></td>
                        <td><input type="text" value={asset.type} placeholder="Select Type" onChange={(e) => handleAssetChange(index, 'type', e.target.value)} /></td>
                        <td><input type="text" value={asset.owner} placeholder="Enter Owner" onChange={(e) => handleAssetChange(index, 'owner', e.target.value)} /></td>
                        <td><input type="text" value={asset.description} placeholder="Write Brief Description" onChange={(e) => handleAssetChange(index, 'description', e.target.value)} /></td>
                        <td>
                            <label className={fileUploadLabel} >
                                <input type="file" className={fileInputClass} onChange={(e) => handleFileUpload(e, index)} />
                                <HiPlus className={fileIconClass} />
                                <span>Upload File</span>
                            </label>
                        </td>
                        <td>
                            <HiTrash onClick={() => removeAsset(index)} />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);



const CoordinatesTable = ({ formData, handleAssetChange, removeAsset }) => (
    <div className={styles['data-entry']}>
        <table className={styles['data-table']}>
            <thead>
                <tr>
                    <th>No</th>
                    <th>Asset Name</th>
                    <th>Asset Type</th>
                    <th>Latitudes</th>
                    <th>Longitude</th>
                    <th>Asset Owner</th>
                    <th>Brief Description</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody style={{ maxHeight: '400px', overflowY: 'auto' }}>
                {formData.assets.map((asset, index) => (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td><input type="text" value={asset.name} placeholder="Enter Asset name" onChange={(e) => handleAssetChange(index, 'name', e.target.value)} /></td>
                        <td><input type="text" value={asset.type} placeholder="Select Type" onChange={(e) => handleAssetChange(index, 'type', e.target.value)} /></td>
                        <td><input type="text" value={asset.latitude} placeholder="Latitudes" onChange={(e) => handleAssetChange(index, 'latitude', e.target.value)} /></td>
                        <td><input type="text" value={asset.longitude} placeholder="Longitude" onChange={(e) => handleAssetChange(index, 'longitude', e.target.value)} /></td>
                        <td><input type="text" value={asset.owner} placeholder="Enter Owner" onChange={(e) => handleAssetChange(index, 'owner', e.target.value)} /></td>
                        <td><input type="text" value={asset.description} placeholder="Write Brief Description" onChange={(e) => handleAssetChange(index, 'description', e.target.value)} /></td>
                        <td>
                            <HiTrash onClick={() => removeAsset(index)} />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);


const AddressesTable = ({ formData, handleAssetChange, removeAsset }) => (
    <div className={styles['data-entry']}>
        <table className={styles['data-table']}>
            <thead>
                <tr>
                    <th>No</th>
                    <th>Asset Name</th>
                    <th>Industry</th>
                    <th>Address</th>
                    <th>Asset Owner</th>
                    <th>Brief Description</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody style={{ maxHeight: '400px', overflowY: 'auto' }}>
                {formData.assets.map((asset, index) => (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td><input type="text" value={asset.name} placeholder="Enter Asset name" onChange={(e) => handleAssetChange(index, 'name', e.target.value)} /></td>
                        <td><input type="text" value={asset.type} placeholder="Select Type" onChange={(e) => handleAssetChange(index, 'type', e.target.value)} /></td>
                        <td><input type="text" value={asset.address} placeholder="Enter Address" onChange={(e) => handleAssetChange(index, 'address', e.target.value)} /></td>
                        <td><input type="text" value={asset.owner} placeholder="Enter Owner" onChange={(e) => handleAssetChange(index, 'owner', e.target.value)} /></td>
                        <td><input type="text" value={asset.description} placeholder="Write Brief Description" onChange={(e) => handleAssetChange(index, 'description', e.target.value)} /></td>
                        <td>
                            <HiTrash onClick={() => removeAsset(index)} />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);

export default ReportStepAssetDetails;
