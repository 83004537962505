/**
 * ReportStepCheckOut Component
 * 
 * Handles the checkout process for generating a report. It integrates with Stripe for payment processing.
 * This component consists of two parts: an order summary drawer and payment details form.
 * 
 * PROPS:
 * - formData (object): Data entered by the user in previous steps.
 * - prevStep (function): Moves back to the previous step.
 * - submitForm (function): Submits the form after the payment is successfully processed.
 * 
 * STATE:
 * - isDrawerOpen (boolean): Determines whether the order summary drawer is open or closed.
 * 
 * FUNCTIONALITY:
 * - toggleDrawer: Opens and closes the order summary drawer.
 * - Displays the payment details form integrated with Stripe, allowing users to complete their payment.
 */

'use client'

import React, { useState, useEffect } from 'react';
import { IoCloseCircleOutline } from "react-icons/io5";
import { FaCartArrowDown } from 'react-icons/fa';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { ReportSelect } from 'components/lib';
import { useAPI } from 'components/lib';

import styles from './ReportStepCheckOut.module.scss';
import validateZipCode from 'utils/validateZipCode';

const Settings = require("../../../settings.json")
const stripePromise = loadStripe(
    Settings[process.env.REACT_APP_NODE_ENV].stripe.publishableAPIKey
);

const statesOfUS = [
    "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado",
    "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho",
    "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana",
    "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota",
    "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada",
    "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina",
    "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania",
    "Rhode Island", "South Carolina", "South Dakota", "Tennessee",
    "Texas", "Utah", "Vermont", "Virginia", "Washington",
    "West Virginia", "Wisconsin", "Wyoming"
];

const ReportStepCheckOut = ({ formData, prevStep, submitForm }) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };


    return (
        <div className={styles['report-step']}>
            <OrderSummaryDrawer isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} formData={formData} />
            <Elements stripe={stripePromise}>
                <PaymentDetails
                    toggleDrawer={toggleDrawer}
                    isDrawerOpen={isDrawerOpen}
                    submitForm={submitForm}
                    formData={formData}

                />
            </Elements>
        </div>
    );
};

const OrderSummaryDrawer = ({ isDrawerOpen, toggleDrawer, formData }) => {

    const itemPrices = {
        validatedAssetArea: 50,
        annualScope1GhfEmissions: 350,
        monthlyScope1GhEmissions: 150,
        scope1GasBreakdown: 350,
        annualScope2GhgEmissions: 200,
        emissionsOffsets: 200,
        dataFile: 0,
        analyticsPlatform: 25,
        pdfReport: 10,
    };

    const selectedItems = Object.entries(formData)
        .filter(([key, value]) => {
            if (itemPrices.hasOwnProperty(key)) {
                return typeof value === 'object' || value === true;
            }
            return false;
        })
        .map(([key, value]) => ({
            title: key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()),
            price: itemPrices[key]
        }));

    return (
        <div className={`${styles['order-summary-drawer']} ${isDrawerOpen ? styles['drawer-open'] : ''}`}>
            <h3>Total Order Items</h3>
            <button className={styles['drawer-toggle-close']} onClick={toggleDrawer}>
                {isDrawerOpen ? <IoCloseCircleOutline /> : <FaCartArrowDown />}
            </button>
            {selectedItems.map((item, index) => (
                <OrderItem key={index} title={item.title} price={`$${item.price.toFixed(2)}`} />
            ))}
            <OrderSummaryFooter formData={formData} />
        </div>

    )
};

const OrderItem = ({ title, price }) => (
    <div className={styles['order-item']}>
        <p>{title}</p>
        <div className={styles['order-item-price']}>
            <span>{price}</span>
            <span>Total Price (USD)</span>
        </div>
    </div>
);

const OrderSummaryFooter = ({ formData }) => {
    const taxRate = 0.08;
    const subtotal = formData.totalPrice || 0;
    const tax = subtotal * taxRate;
    const total = subtotal + tax;

    const formatCurrency = (value) => {
        if (value === undefined || value === null || isNaN(value)) {
            return '$0.00';
        }
        return `$${Number(value).toFixed(2)}`;
    };

    const formatPerAsset = (value) => {
        if (value === undefined || value === null || isNaN(value)) {
            return '$0.000';
        }
        return `$${Number(value).toFixed(3)}`;
    };

    return (
        <>
            <div className={styles['subtotal-section']}>
                <div className={styles.left}>
                    <p>Subtotal</p>
                    <p className={styles.subnote}>({formatPerAsset(formData.pricePerAsset)} Per asset)</p>
                </div>
                <div className={styles.right}>
                    <p>{formatCurrency(subtotal)}</p>
                    <p className={styles.subnote}>({formatPerAsset(formData.pricePerAsset)} Per asset)</p>
                </div>
            </div>
            <div className={styles['tax-section']}>
                <div className={styles.left}>
                    <p>Tax</p>
                </div>
                <div className={styles.right}>
                    <p>{formatCurrency(tax)}</p>
                </div>
            </div>
            <div className={styles['total-price-section']}>
                <p>Total Price (USD)</p>
                <span>{formatCurrency(total)}</span>
            </div>
        </>
    );
};

const PaymentDetails = ({ toggleDrawer, isDrawerOpen, submitForm, formData }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('US');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [isZipCodeValid, setIsZipCodeValid] = useState(true);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [requestData, setRequestData] = useState(null);
    const [citySuggestions, setCitySuggestions] = useState([]);
    const [cityPlaceholder, setCityPlaceholder] = useState('Enter city');
    const { data, loading, error: apiError } = useAPI(requestData ? '/api/checkout' : null, 'post', requestData);
    const subtotal = formData.totalPrice || 0;
    const taxRate = 0.08;
    const tax = subtotal * taxRate;
    const totalPriceWithTax = subtotal + tax;

    const handleZipCodeChange = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        setZipCode(value);
        setIsZipCodeValid(validateZipCode(value));
    };

    const handleCityInputChange = async (e) => {
        if (!state) {
            setCity('');
            setCitySuggestions([]);
            setCityPlaceholder('Please select a state first');
            return;
        }

        const value = e.target.value;
        setCity(value);


        if (typeof window.google === 'undefined' || typeof window.google.maps === 'undefined') {
            console.error('Google Maps API is not loaded.');
            return;
        }

        try {
            const autocompleteService = new window.google.maps.places.AutocompleteService();
            autocompleteService.getPlacePredictions(
                {
                    input: value,
                    componentRestrictions: { country: 'us' },
                    types: ['(cities)'],
                },
                (predictions, status) => {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                        setCitySuggestions(predictions.map(prediction => prediction.description));
                    } else {
                        console.error("Error fetching city suggestions:", status);
                    }
                }
            );
        } catch (error) {
            console.error("Error fetching city suggestions:", error);
        }
    };

    const handleStateChange = (e) => {
        const selectedState = e.target.value;
        setState(selectedState);
        setCity('');
        setZipCode('');
        setCitySuggestions([]);
        setCityPlaceholder('Enter city')
    };

    const handleCitySelect = async (selectedCity) => {
        setCitySuggestions([]);

        try {
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ address: selectedCity }, (results, status) => {
                if (status === 'OK' && results.length > 0) {
                    const addressComponents = results[0].address_components;


                    const cityComponent = addressComponents.find(component => component.types.includes('locality'));
                    if (cityComponent) {
                        setCity(cityComponent.long_name);
                    }


                    const zipComponent = addressComponents.find(component => component.types.includes('postal_code'));
                    if (zipComponent) {
                        setZipCode(zipComponent.long_name);
                    }


                    const stateComponent = addressComponents.find(component => component.types.includes('administrative_area_level_1'));
                    if (stateComponent) {
                        setState(stateComponent.long_name);
                    }

                } else {
                    console.error("Geocode was not successful:", status);
                }
            });
        } catch (error) {
            console.error("Error geocoding city:", error);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setProcessing(true);
        setError(null);

        if (!stripe || !elements) {
            setError("Stripe has not loaded");
            setProcessing(false);
            return;
        }

        const cardElement = elements.getElement(CardElement);

        const billingDetails = {
            name,
            address: {
                line1: address,
                city,
                state,
                postal_code: zipCode,
                country,
            },
        };

        try {
            const { error: methodError, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: billingDetails,
            });

            if (methodError) {
                setError(methodError.message);
                setProcessing(false);
                return;
            }

            const amountInDollars = Number(totalPriceWithTax);

            if (isNaN(amountInDollars)) {
                setError("Invalid amount. Please enter a valid payment amount.");
                setProcessing(false);
                return;
            }

            setRequestData({
                paymentMethodId: paymentMethod.id,
                amount: amountInDollars,
            });

        } catch (error) {
            setError('Payment failed. ' + error.message);
            setProcessing(false);
        }
    };

    useEffect(() => {
        if (!loading) {
            if (data) {
                const { paymentIntent } = data;

                if (paymentIntent?.status === 'succeeded') {
                    console.log('Payment successful', paymentIntent);
                    setError(null);
                    setProcessing(false);
                    submitForm();
                } else {
                    setError('Payment not successful');
                    setProcessing(false);
                }
            } else if (apiError) {
                setError('API Error: ' + apiError);
                setProcessing(false);
            }
        }
    }, [loading, data, apiError, submitForm]);

    return (
        <form onSubmit={handleSubmit} className={styles['payment-details']}>
            <button className={styles['drawer-toggle']} onClick={toggleDrawer}>
                {isDrawerOpen ? "" : <FaCartArrowDown />}
            </button>
            <h3>Payment Details</h3>
            <div className={styles['payment-details-content']}>
                <div className={styles['input-field']}>
                    <label>Card Details</label>
                    <CardElement
                        options={{
                            style: {
                                base: {
                                    fontSize: '14px',
                                    color: '#616161',
                                },
                                invalid: {
                                    color: '#9e2146',
                                },
                            },
                        }}
                    />
                </div>
                <InputField
                    label="Name On Payment Method"
                    placeholder="Enter name on payment method"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <InputField
                    label="Billing Address"
                    placeholder="Enter billing address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                />
                <div className={styles.row}>
                    <InputFieldSelect
                        label="Country"
                        options={["US"]}
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                    />
                    <InputField
                        label="City"
                        placeholder={cityPlaceholder}
                        value={city}
                        onChange={handleCityInputChange}
                    />
                    {citySuggestions.length > 0 && (
                        <ul className={styles['city-suggestions']}>
                            {citySuggestions.map((suggestion, index) => (
                                <li key={index} onClick={() => handleCitySelect(suggestion)}>
                                    {suggestion}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                <div className={styles.row}>
                    <InputFieldSelect
                        label="State"
                        options={statesOfUS}
                        value={state}
                        onChange={handleStateChange}
                    />
                    <InputField
                        label="Zip Code"
                        placeholder="Enter zip code"
                        value={zipCode}
                        onChange={handleZipCodeChange}
                        isValid={isZipCodeValid}
                        errorMessage="Invalid zip code"
                    />
                </div>
            </div>
            <div className={styles['checkbox-field']}>
                <input type="checkbox" />
                <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
            </div>
            {error && <div className={styles['error-message']}>{error}</div>}
            <button
                type="submit"
                disabled={!stripe || processing}
                className={styles['submit-button']}
            >
                {processing ? 'Processing...' : 'Check Out'}
            </button>
            <p className={styles['submit-button-subtitle']}>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.
            </p>
        </form>
    );
};

const InputField = ({ label, placeholder, value, onChange, type = 'text', maxLength, isValid = true, icon, errorMessage }) => (
    <div className={`${styles['input-field']} ${!isValid ? styles['invalid'] : ''}`}>
        <label>{label}</label>
        <div className={styles['input-with-icon']}>
            <input
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                maxLength={maxLength}
            />
            {icon && <div className={styles.icon}>{icon}</div>}
        </div>
        {!isValid && <span className={styles['error-text']}>{errorMessage}</span>}
    </div>
);

const InputFieldSelect = ({ label, options, value, onChange }) => (
    <div className={styles['input-field']}>
        <label>{label}</label>
        <div className={styles['input-field-select']}>
            <ReportSelect
                name={label}
                options={options}
                value={value}
                onChange={onChange}
            />
        </div>
    </div>
);

export default ReportStepCheckOut;