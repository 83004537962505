/***
*
*   RESET PASSWORD
*   User can set a new password using the token
*
**********/

import React, { useContext } from 'react';
import { Animate, Row, AuthContext, Card, Form } from 'components/lib';

export function ResetPassword(props){

  // context
  const context = useContext(AuthContext)

  // check for token
  const qs = props.location.search;
  const token = qs.substring(qs.indexOf('?token=') + 7);

  return(
    <Animate type='pop'>
      <Row title='Reset Your Password'>
        <Card restrictWidth center>
          <Form
            data={{
              jwt: {
                type: 'hidden',
                value: token,
              },
              email: {
                label: 'Email',
                type: 'email'
              },
              password: {
                label: 'New Password',
                type: 'password',
                required: true,
              }
            }}
            url='/api/user/password/reset'
            method='POST'
            buttonText='Set New Password'
            callback={ data => {

              context.signin(data);

            }}
          />
          </Card>
      </Row>
    </Animate>
  );
}
