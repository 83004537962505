/***
*
*   LINK
*   Routes a new view within the application router
*   Use this instead of <a> to avoid reloading the page
*
*   PROPS
*   url: the destination as defined in /app/app.js
*   title: link title
*   text: link text
*   btn: display a button
*   small: display a small button
*   className: apply a custom css class
*
**********/

import React from 'react';
import ClassNames from 'classnames';
import { NavLink } from 'react-router-dom';
import Style from '../button/button.module.scss';

export function Link(props){

  const css = ClassNames([

    'link',
    props.btn && Style.btn,
    props.big && Style.big,
    props.small && Style.small,
    props.link && Style.link,
    props.className
  ]);

  if (props.url.includes('http')){
    //Nasty workaround to allow us to link back and forth from the main site:
    if(!props.url.includes("floodlightinvest.com")){
      //Force to text link:
      return (
          <div className={ css } onClick={() => props.url} title={ props.title }>
            { props.text }
          </div>
      )
    } else {
      return (
          //else force to pass children down, such as images, etc (fixes logo as "external" link)
          <div onClick={ () => props.url } title={ props.title } className={ css }>
            { props.children }
          </div>
      )
    }
  }

  return(
    <NavLink
      exact to={ props.url }
      className={ css }
      title={ props.title }
      activeClassName='active'>

      { props.children ? props.children : props.text }

    </NavLink>

  );
}
