import { useState } from "react";
import { FaCaretUp, FaCaretDown } from "react-icons/fa";

export const ReportSelect = ({ name, options, value, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleSelect = (option) => {
        onChange({ target: { value: option } });
        setIsOpen(false);
    };

    return (
        <div className="selectWrapper">
            <div
                className="selectButton brd"
                onClick={() => setIsOpen(!isOpen)}
            >
                <div className="selectedValue">
                    <span style={{ color: value ? "#212121" : "#9E9E9E" }}>{value || `${name}`}</span>
                </div>
                <div className="chevrons">
                    {isOpen ? <FaCaretUp color={value ? "#212121" : "#9E9E9E"} /> : <FaCaretDown color={value ? "#212121" : "#9E9E9E"} />}
                </div>
            </div>

            {isOpen && (
                <div className="options">
                    {options.map((option, index) => (
                        <div
                            className="option"
                            key={index}
                            onClick={() => handleSelect(option)}
                        >
                            <span>{option}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};