import React, { useState } from "react";
import ClassNames from "classnames";
import { NavLink } from "react-router-dom";
import { Logo, Icon, Image } from "components/lib";
import ReportGenerationModal from "components/report_generation_modal/ReportGenerationModal";
import { SwitchCard } from "../switchcard/SwitchCard";
import "./app.scss";
import divider from "./images/Vector 6.svg";
import sageLogo from "../../logo/images/SAGE Transparent Black Cropped.png";

export function AppNav(props) {
  // state
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);
  const type = "mobile-" + props.type;

  // style
  const cssClass = ClassNames({
    sidebar: true,
    open: open,
    expanded: expanded,
    [type]: props.type,
  });

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  return (
    <>
      <nav className={cssClass}>
        {props.type === "popup" && (
          <button
            className="btn-togglenav"
            onClick={() => setOpen(!open)}
          >
            {open ? "X" : "☰"} {/* Simple menu icon/text */}
          </button>
        )}
        {expanded ? <Logo /> : <Logo mark />}
        <img src={divider} className="divider" alt="divider" />
        <div className="nav-memnu">
          <SwitchCard
            logo={sageLogo}
            title="Saga"
          />
          <button
            className="generateReportButton"
            onClick={toggleModal}
          >
            + Generate New Report
          </button>

        </div>
      </nav >

      {isModalOpen && (
        <ReportGenerationModal isOpen={isModalOpen} onClose={toggleModal} />
      )
      }

      <div>

      </div>
    </>
  );
}

export default AppNav;
