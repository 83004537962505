import { MasterDashboard } from "views/master/dashboard";
import { MasterAccounts } from "views/master/accounts";
import { MasterUsers } from "views/master/users";
import { MasterFeedback } from "views/master/feedback";
import { MasterToolsKanari } from "../views/master/kanari";
import { MasterProjects } from "../views/master/projects";
import { MasterSageOracle } from "../views/master/sage_oracle";
import { MasterRestProjects } from "views/master/rest_projects";

const master = [
  {
    path: "/master/dashboard",
    view: MasterDashboard,
    layout: "master",
    permission: "master",
    title: "Master Dashboard",
  },
  {
    path: "/master/accounts",
    view: MasterAccounts,
    layout: "master",
    permission: "master",
    title: "Accounts",
  },
  {
    path: "/master/users",
    view: MasterUsers,
    layout: "master",
    permission: "master",
    title: "Users",
  },
  {
    path: "/master/feedback",
    view: MasterFeedback,
    layout: "master",
    permission: "master",
    title: "User Feedback",
  },
  {
    path: "/master/projects",
    view: MasterProjects,
    layout: "master",
    permission: "master",
    title: "SAGE Projects",
  },
  {
    path: "/master/rest-projects",
    view: MasterRestProjects,
    layout: "master",
    permission: "master",
    title: "REST Projects",
  },
  {
    path: "/master/kanari",
    view: MasterToolsKanari,
    layout: "master",
    permission: "master",
    title: "Kanari",
  },
  {
    path: "/master/sage-oracle",
    view: MasterSageOracle,
    layout: "master",
    permission: "master",
    title: "SAGE: Oracle",
  },
];
export default master