import React, { Fragment, useState } from "react";
// import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "../../../components/checkout/checkout_form/checkout_form";
import { Button, Card } from "../../../components/lib";
import Style from "./credits_landing.module.scss";
import { HorizontalDivider } from "../../../components/layout/divider/horizontal_divider";
import NumericInput from "react-numeric-input";

export function PurchaseCreditsFlow(props) {
  const [showCheckout, setShowCheckout] = useState(false);
  const [numOfCredits, setnumOfCredits] = useState(1);

  const handleGoToCheckout = () => {
    setShowCheckout(true);
  };

  const numOfCreditsOnChange = (value) => {
    setnumOfCredits(value);
  };

  const cents = numOfCredits * 450 * 100;
  const dollars = (cents / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });

  if (showCheckout)
    return (
      <Fragment>
        <Card shadow>
          <h1 style={{ marginBottom: "10px" }}>
            Number of Credits: {numOfCredits} Total Cost: {dollars}
          </h1>
          <CheckoutForm numOfCredits={numOfCredits} />
          <div className={Style.buttonHolder}>
            {/*<Button*/}
            {/*    className={Style.nextButton}*/}
            {/*    text="Purchase"*/}
            {/*    action={handlePurchase}*/}
            {/*/>*/}
          </div>
        </Card>
      </Fragment>
    );

  return (
    <Fragment>
      <Card shadow>
        <div className={Style.headerContent}>
          <h1>Credit Order Form</h1>
          <p>
            Order credits so you can exchange them for SAGE or REST
            environmental reports. Each credit can be exchanged for one report.
          </p>
        </div>
        <HorizontalDivider />
        {/* <Card className={Style.formWrapper} shadow> */}
        <div className={Style.formRow}>
          <h2>Number of Credits</h2>
          {/* <NumberInput
            name={"numOfCredits"}
            min={1}
            value={numOfCredits}
            max={100}
            onChange={numOfCreditsOnChange}
          /> */}
          <NumericInput
            style={{
              input: {
                height: "34px",
              },
              btn: {
                background: "#ef6239",
                color: "white",
              },
              plus: {
                background: "white",
              },
              minus: {
                background: "white",
              },
            }}
            name={"numOfCredits"}
            min={1}
            value={numOfCredits}
            max={100}
            onChange={numOfCreditsOnChange}
            mobile
          />
          <div className={Style.dollarAmount}>{dollars}</div>
        </div>
        {/* <HorizontalDivider /> */}
        {/* </Card> */}
        <div className={Style.buttonHolder}>
          <Button
            className={Style.nextButton}
            text="Next"
            action={handleGoToCheckout}
          />
        </div>
      </Card>
    </Fragment>
  );
}
