/***
 *
 *   MASTER PROJECTS
 *   Manage all Projects submitted
 *
 **********/

import React, { useContext } from "react";
import { ViewContext, Card, Table, Animate, useAPI } from "components/lib";

export function MasterProjects(props) {
  const context = useContext(ViewContext);
  const projects = useAPI("/api/master/projects/SAGE");

  function editProject(data, callback) {
    context.modal.show(
      {
        title: "Update Project",
        form: {
          id: {
            type: "hidden",
            value: data.project_id,
          },
          project_type: {
            type: "hidden",
            value: data.project_type,
          },
          project_name: {
            label: "Project Name",
            type: "text",
            value: data.project_name,
            required: true,
          },
          start_date: {
            label: "Start Date",
            type: "date",
            value: data.start_date,
            required: true,
          },
          end_date: {
            label: "End Date",
            type: "date",
            value: data.end_date,
            required: true,
          },
          report_link: {
            label: "Report Link",
            type: "text",
            value: data.report_link,
            required: true,
          },
          project_status: {
            label: "Project Status",
            type: "select",
            default: "Submitted",
            options: [
              { label: "Submitted", value: "Submitted" },
              { label: "Received", value: "Received" },
              { label: "Working on Scope 1", value: "Working on Scope 1" },
              { label: "Working on Scope 2", value: "Working on Scope 2" },
              { label: "Quality Checks", value: "Quality Checks" },
              { label: "Delivered", value: "Delivered" },
            ],
            required: true,
          },
          data_file_url: {
            label: "Data File",
            type: "singleFile",
            // accept: ["csv"],
            max: 1,
            maxFileSize: 10,
            value: data.data_file_url,
          },
        },
        buttonText: "Save",
        url: "/api/master/projects/SAGE",
        method: "PATCH",
      },
      (res) => {
        context.notification.show(
          data.project_name + " was updated",
          "success",
          true
        );
        callback(res);
      }
    );
  }

  function deleteProject(data, callback) {
    context.modal.show(
      {
        title: "Delete Project",
        form: {
          id: {
            type: "hidden",
            value: data.id,
          },
        },
        buttonText: "Delete Project",
        text: "Are you sure you want to delete " + data.name + "?",
        url: "/api/master/projects",
        method: "DELETE",
      },
      () => {
        context.notification.show(data.name + " was deleted", "success", true);
        callback();
      }
    );
  }

  return (
    <Animate>
      <Card loading={false}>
        <Table
          search
          data={projects?.data}
          loading={projects?.loading}
          actions={{ edit: editProject, delete: deleteProject }}
        />
      </Card>
    </Animate>
  );
}
