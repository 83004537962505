/***
 *
 *   PASSWORD
 *   Update the users password
 *
 **********/

import React, { Fragment } from "react";
import { Card, Form, AccountNav, Animate } from "components/lib";
import { Box } from "components/box/box";

export function Password(props) {
  return (
    <Fragment>
      <AccountNav />
      <Animate>
        <Box paddingX={1}>
          <Card title="Update Your Password" restrictWidth>
            <Form
              url="/api/user/password"
              method="PATCH"
              buttonText="Save Password"
              data={{
                oldpassword: {
                  label: "Old Password",
                  type: "password",
                  required: true,
                },
                newpassword: {
                  label: "New Password",
                  type: "password",
                  required: true,
                },
              }}
            />
          </Card>
        </Box>
      </Animate>
    </Fragment>
  );
}
