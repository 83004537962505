/***
 *
 *   MASTER LAYOUT
 *   The master dashboard layout containing the navigation
 *   and header (title, secondary nav)
 *
 *   PROPS
 *   title: title of the view
 *
 **********/

import React, { Fragment, useContext } from "react";
import ClassNames from "classnames";
import {
  AuthContext,
  AppNav,
  Header,
  HoverNav,
  Button,
  Link,
} from "components/lib";
import Style from "../app/app.module.scss";

const navLinks = [
  { label: "Dashboard", icon: "activity", link: "/master/dashboard" },
  { label: "Feedback", icon: "heart", link: "/master/feedback" },
  { label: "SAGE Projects", icon: "folder", link: "/master/projects" },
  { label: "REST Projects", icon: "folder", link: "/master/rest-projects" },
  { label: "Accounts", icon: "credit-card", link: "/master/accounts" },
  { label: "Users", icon: "users", link: "/master/users" },
  { label: "Kanari", icon: "crosshair", link: "/master/kanari" },
  { label: "SAGE-Oracle", icon: "crosshair", link: "/master/sage-oracle" },
];

export function MasterLayout(props) {
  // context
  const context = useContext(AuthContext);

  // style
  const css = ClassNames([Style.app, "with-sidebar"]);

  return (
    <Fragment>
      <AppNav type="popup" items={[]} />

      <main className={css}>
        <Header title={props.title}>
          <nav className={Style.spacedNav}>
            {navLinks.map((link, index) => {
              return <Link link url={link.link} text={link.label} key={index} />;
            })}
          </nav>
          <HoverNav label={context.user.name} align="right" dark>
            <Button text="Signout" action={context.signout} />
          </HoverNav>
        </Header>

        {<props.children {...props.data} />}
      </main>
    </Fragment>
  );
}
