/**
 * ReportModalFooter Component
 * 
 * This component displays the footer of the modal, which typically contains navigation buttons like "Next" and "Previous."
 * It conditionally displays a submit button if it's the last step.
 * 
 * PROPS:
 * - step (number): Current step in the modal process.
 * - onNext (function): Function to proceed to the next step or submit the form.
 * - onPrev (function): Function to go back to the previous step.
 * - isLastStep (boolean): Boolean indicating whether the current step is the last one.
 * 
 * USAGE:
 * Renders a "Next" or "Generate Order Form" button based on the current step, and optionally a "Previous" button.
 */

import React from 'react';

function ReportModalFooter({ step, onNext, onPrev, isLastStep }) {
    return (
        <div className="modalFooter">
            {step === 1 ? <div></div> : <button className="backButton" onClick={onPrev}>
                Back
            </button>}

            <button className="nextButton" onClick={onNext}>
                {isLastStep ? 'Generate Order Form' : 'Next'}
            </button>
        </div>
    );
}

export default ReportModalFooter;
