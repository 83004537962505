import React, {useEffect, useRef, useState} from "react";
import {Animate} from "../../components/animate/animate";
import {Card} from "../../components/card/card";
import {FileDropzone} from "../../components/file_dropzone/file_dropzone";
import {Loader} from "../../components/loader/loader";
import osmtogeojson from "osmtogeojson";
import {booleanWithin} from "@turf/turf";
import Axios from "axios";
import {Button, Row} from "../../components/lib";
import {AddressVerificationOracle} from "./oracle_tools/address_verification";
const { RateLimit } = require('async-sema');


const geoJSONTemplate = '{\n' +
    '"type": "FeatureCollection",\n' +
    '"name": "scope_2_out",\n' +
    '"features": [<<features>>]}';

const rateLimit = RateLimit(1, {timeUnit: 10000});


const getAllBoundingBoxes = (geoJSON) => {
    const parsedJSON = JSON.parse(geoJSON);
    const ret = [];
    for(let feature of parsedJSON['features']){
        const coordinates = feature['geometry']['coordinates'];
        let xMin, xMax, yMin, yMax;
        for(let coordinate of coordinates[0][0]){
            (xMin === undefined) ? (xMin = coordinate[0]) : ( (xMin > coordinate[0]) && (xMin = coordinate[0]) );
            (xMax === undefined) ? (xMax = coordinate[0]) : ( (xMax < coordinate[0]) && (xMax = coordinate[0]) );
            (yMin === undefined) ? (yMin = coordinate[1]) : ( (yMin > coordinate[1]) && (yMin = coordinate[1]) );
            (yMax === undefined) ? (yMax = coordinate[1]) : ( (yMax < coordinate[1]) && (yMax = coordinate[1]) );
        }
        ret.push({'xMin': xMin, 'xMax': xMax, 'yMin': yMin, 'yMax':yMax});
    }
    return ret;
}

// const createOutputGeoJSON = (minMaxArray) => {
//     // coords -> [  [ -86.185235883314675, 36.76307861490578 ] ]
//     const featureTemplate = '{ "type": "Feature", "properties": { <<props>> }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ <<coords>> ] ] } }';
//     let features = '';
//     let counter = 1;
//     for(let feature of minMaxArray){
//         const props = '"id": "' + counter + '"';
//         const coords = '[[' + feature.xMin +', ' + feature.yMin + '],' +
//             ' [' + feature.xMax +', ' + feature.yMin + '],' +
//             ' [' + feature.xMax +', ' + feature.yMax + '],' +
//             ' [' + feature.xMin +', ' + feature.yMax + '],' +
//             ' [' + feature.xMin +', ' + feature.yMin + ']]';
//         const featureString = featureTemplate.replace('<<props>>', props).replace('<<coords>>', coords);
//         features = features + featureString + ',';
//         counter += 1;
//     }
//     features = features.substring(0, features.length - 1); // Remove trailing comma
//     return geoJSONTemplate.replace('<<features>>', features);
// }

const getOSMBuildingsFromApi = async (bbox) => {
    const overpassAPIUrl = 'https://overpass-api.de/api/interpreter';
    // Raw: %5Bout%3Ajson%5D%5Btimeout%3A25%5D%3B%0A%2F%2F%20gather%20results%0A(%0A%20%20%2F%2F%20query%20part%20for%3A%20“building”%0A%20%20way%5B"building"%5D(51.13945552819933%2C4.909472465515136%2C51.15439717568624%2C4.935007095336914)%3B%0A%20%20relation%5B"building"%5D(51.13945552819933%2C4.909472465515136%2C51.15439717568624%2C4.935007095336914)%3B%0A)%3B%0A%2F%2F%20print%20results%0Aout%20body%3B%0A>%3B%0Aout%20skel%20qt%3B&target=mapql
    // <<coord>> -> 51.13945552819933%2C4.909472465515136%2C51.15439717568624%2C4.935007095336914
    const requestTemplateEncoded = '%5Bout%3Ajson%5D%5Btimeout%3A25%5D%3B%0A%2F%2F%20gather%20results%0A(%0A%20%20%2F%2F%20query%20part%20for%3A%20“building”%0A%20%20way%5B%22building%22%5D(<<coord>>)%3B%0A%20%20relation%5B%22building%22%5D(<<coord>>)%3B%0A)%3B%0A%2F%2F%20print%20results%0Aout%20body%3B%0A>%3B%0Aout%20skel%20qt%3B&target=mapql';
    const coordString = bbox.yMin + "%2C" + bbox.xMin + "%2C" + bbox.yMax + "%2C" + bbox.xMax;
    let encoded = requestTemplateEncoded.replaceAll('<<coord>>', coordString);
    const requestString = overpassAPIUrl + "?data=" + encoded;
    // await new Promise(r => setTimeout(r, Math.random()*300000));
    await rateLimit();
    return Axios.get(requestString);
}


export function MasterSageOracle(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [outScope2ShapesGeoJSON, setOutScope2ShapesGeoJSON] = useState('');
    const [downloadScope2URI, setDownloadScope2URI] = useState('');
    const [complete, setComplete] = useState(false);

    const dlRef = useRef(null);

    const downloadButtonClick = () => {
        dlRef.current.click();
    }

    useEffect( ()=> {
        // const stringify = JSON.stringify(outScope2ShapesGeoJSON);
        const stringify = outScope2ShapesGeoJSON;
        const blob = new Blob([stringify], {type: "application/geo+json"});
        const fileDownloadURI = URL.createObjectURL(blob);
        setDownloadScope2URI(fileDownloadURI);
    },[outScope2ShapesGeoJSON] );

    const onFileChange = (files) => {
        for(let f of files){
            // if(f.type !== 'application/geo+json')
            //     return;
            const fileReader = new FileReader();
            fileReader.onload = async (_) => {
                setIsLoading(true);
                // const geoJSON = JSON.parse(evt.target.result);
                const bounds = getAllBoundingBoxes(fileReader.result);const apiData = await Promise.all(
                    bounds.map( (bound) => getOSMBuildingsFromApi(bound))
                );
                const processesGeoJSONS = apiData.map( (item) => {
                    return osmtogeojson(item.data);
                } );
                const processedFeatures = processesGeoJSONS.map( (item) => {
                    return item['features'];
                } );
                let features = '';
                for(let feature_ in processedFeatures){
                    for(let feature of processedFeatures[feature_]) {
                        // Check to see if the building is within the original polygon from scope 1, and only include those:
                        const test = booleanWithin(feature, JSON.parse(fileReader.result)['features'][feature_]);
                        // console.log("test: " + test + ", for feature:" + feature);
                        if(test)
                            features = features + JSON.stringify(feature) + ',';
                    }
                }
                features = features.substring(0, features.length - 1); // Remove trailing comma
                const result = geoJSONTemplate.replace('<<features>>', features);
                setIsLoading(false);
                setComplete(true);
                setOutScope2ShapesGeoJSON(result);
                // setOutGeoJSON(result);
            }
            fileReader.readAsText(f.file);
        }
    };

    return(
        <Animate>
            <Card
                title="Scope 1 to Scope 2 Oracle"
            >
                <Row>
                    This tool allows you to upload a scope 1 boundary file and receive the scope 2 boundaries for all buildings in the boundaries.
                    The format required must be .geoJSON as this is standard web format for geospatial inputs and outputs. The process will take
                    each polygon in the scope 1 file and create a bounding box for each element. This is then passed to Open Street Map API. The output
                    is then re-converted to a geoJSON file. This file is then processed again to filter out any buildings that are within the square bounding
                    box but outside the input polygons. The final result is a single geoJSON file with all the building features from Open Street Map.
                    You must drag the .geoJSON scope 1 boundary file to this area, and it will automatically process the results.
                </Row>
                <FileDropzone fileChangeCB={onFileChange}/>
                If you run this a second time, please make sure to either reload the page or click the 'x' button to clear the current file(s).
            </Card>
            <Card>
                {isLoading ? <Loader/> :
                <Row>
                    {complete &&
                        <Button
                            loading={!complete}
                            text="Download geoJSON Output"
                            action={downloadButtonClick}
                            pullRight={true}
                        />
                    }
                    <a style={{display: "none"}}
                       download={'scope_2_output.geojson'}
                       href={downloadScope2URI}
                       ref={dlRef}
                       aria-label={'Download geoJSON Output'}
                    >download report</a>
                </Row>
                }
            </Card>
            <Card
                title="Address Verification"
            >
                <AddressVerificationOracle/>
            </Card>
        </Animate>
    );

}