/**
 * ReportGenerationModal Component
 * 
 * Handles the multi-step process for generating a report, including validating input, processing payment, and submitting the report.
 * 
 * PROPS:
 * - isOpen (boolean): Determines if the modal is open.
 * - onClose (function): Function to close the modal.
 * 
 * STATE:
 * - step (number): Tracks the current step in the report generation process.
 * - errors (object): Stores validation errors for each step.
 * - formData (object): Stores the data entered by the user throughout the process.
 * - reportPayload (object): The final payload to be sent for report generation.
 * 
 * FUNCTIONALITY:
 * - validateCurrentStep: Validates the form fields for the current step.
 * - nextStep: Moves to the next step in the process after validation.
 * - prevStep: Moves to the previous step.
 * - submitForm: Submits the report data after all steps are validated.
 */

import React, { useState, useCallback, useEffect } from 'react';
import ReportModalHeader from './ReportModalHeader';
import ReportModalBody from './ReportModalBody';
import ReportModalFooter from './ReportModalFooter';
import { ReportModal, useAPI } from 'components/lib';
import {
    validateRequestingEntity,
    validateEntityType,
    validateNumberOfAssets,
    validateAssetType
} from 'utils/validationReport';

function ReportGenerationModal({ isOpen, onClose }) {
    const [step, setStep] = useState(1);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        requestingEntity: '',
        entityType: '',
        numberOfAssets: '',
        assetType: '',
        assets: [],
        assetSummary: { count: 0, type: 'Mixed' },
        useCase: '',
        reportSpecifications: [],
        reportItems: [],
        ghgReportingOrgLevel: false,
        ghgReportingProjectLevel: false,
        ghgVerification: false,
        other: false,
        ghgProtocolAligned: false,
        isoStandard: false,
        none: false,
        validatedAssetArea: false,
        annualScope1GhfEmissions: false,
        monthlyScope1GhEmissions: false,
        scope1GasBreakdown: false,
        annualScope2GhgEmissions: false,
        emissionsOffsets: false,
        dataFile: false,
        analyticsPlatform: false,
        pdfReport: false,
        totalPrice: 0,
        pricePerAsset: 0,
    });

    const [reportPayload, setReportPayload] = useState(null);
    const [shouldCallAPI, setShouldCallAPI] = useState(false);
    const { data: reportData } = useAPI(
        shouldCallAPI ? '/api/report' : null,
        'post',
        reportPayload
    );

    const validateCurrentStep = useCallback(() => {
        const newErrors = {};

        if (step === 1) {
            newErrors.requestingEntity = validateRequestingEntity(formData.requestingEntity);
            newErrors.entityType = validateEntityType(formData.entityType);
            newErrors.numberOfAssets = validateNumberOfAssets(formData.numberOfAssets);
            newErrors.assetType = validateAssetType(formData.assetType);
        }

        if (step === 2) {
            if (formData.assets.length === 0) {
                newErrors.assets = "You must add at least one asset before proceeding.";
            }
        }

        if (step === 3) {
            if (!formData.ghgReportingOrgLevel && !formData.ghgReportingProjectLevel && !formData.ghgVerification && !formData.other) {
                newErrors.useCase = "Please select at least one use case.";
            }
            if (!formData.ghgProtocolAligned && !formData.isoStandard && !formData.none) {
                newErrors.reportSpecifications = "Please select at least one report specification.";
            }
            if (!formData.validatedAssetArea && !formData.annualScope1GhfEmissions && !formData.monthlyScope1GhEmissions &&
                !formData.scope1GasBreakdown && !formData.annualScope2GhgEmissions && !formData.emissionsOffsets) {
                newErrors.reportItems = "Please select at least one report item.";
            }
        }

        setErrors(newErrors);

        return Object.values(newErrors).every((error) => !error);
    }, [step, formData]);

    const nextStep = useCallback(() => {
        if (validateCurrentStep()) {
            if (step === 3) {
                const reportData = {
                    report_type: formData.useCase,
                    entity_type: formData.entityType,
                    asset_count: formData.numberOfAssets,
                    asset_type: formData.assetType,
                    asset_details: JSON.stringify(formData.assets),
                    report_specifications: JSON.stringify({
                        ghgProtocolAligned: formData.ghgProtocolAligned,
                        isoStandard: formData.isoStandard,
                        none: formData.none,
                    }),
                    report_summary: JSON.stringify({
                        validatedAssetArea: formData.validatedAssetArea,
                        annualScope1GhfEmissions: formData.annualScope1GhfEmissions,
                        monthlyScope1GhEmissions: formData.monthlyScope1GhEmissions,
                        scope1GasBreakdown: formData.scope1GasBreakdown,
                        annualScope2GhgEmissions: formData.annualScope2GhgEmissions,
                        emissionsOffsets: formData.emissionsOffsets,
                        dataFile: formData.dataFile,
                        analyticsPlatform: formData.analyticsPlatform,
                        pdfReport: formData.pdfReport,
                        billingData: formData.uploadedBillData
                    }),
                };
                setReportPayload(reportData);
            }
            setStep(step + 1);
        }
    }, [step, formData, validateCurrentStep]);

    const prevStep = useCallback(() => {
        setStep(step - 1);
    }, [step]);

    const submitForm = useCallback(() => {
        if (validateCurrentStep()) {
            setShouldCallAPI(true);
        }
    }, [validateCurrentStep]);

    useEffect(() => {
        if (reportData) {
            setStep(prevStep => prevStep + 1);
        }
    }, [reportData]);

    useEffect(() => {
        if (!isOpen) {
            setShouldCallAPI(false);
        }
    }, [isOpen]);

    useEffect(() => {
        if (reportPayload && shouldCallAPI)
            setShouldCallAPI(false);
    }, [reportPayload, shouldCallAPI])

    return (
        <ReportModal isOpen={isOpen} onClose={onClose}>
            <ReportModalHeader
                step={step}
                title="Generate New Report"
                subtitle="Fill this form out to submit a new report to be run."
                onClose={onClose}
            />
            <ReportModalBody
                step={step}
                formData={formData}
                setFormData={setFormData}
                nextStep={nextStep}
                prevStep={prevStep}
                errors={errors}
                submitForm={submitForm}
            />
            {step !== 4 && (
                <ReportModalFooter
                    step={step}
                    onNext={nextStep}
                    onPrev={prevStep}
                    isLastStep={step === 4}
                />
            )}
        </ReportModal>
    );
}

export default ReportGenerationModal;