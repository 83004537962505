/**
 * ReportStepUseCaseSelection Component
 * 
 * Allows users to select various report items and use cases for generating a custom report.
 * Users can select from a list of report items, enter additional information, and upload files where needed.
 * 
 * PROPS:
 * - formData (object): Data entered by the user in previous steps.
 * - setFormData (function): Updates the form data based on user input.
 * - nextStep (function): Moves to the next step in the report generation process.
 * - prevStep (function): Moves to the previous step in the report generation process.
 * - errors (object): Validation errors for the report item selections.
 * 
 * STATE:
 * - allChecked (boolean): Determines if all report items should be selected.
 * - uploadedFile (object): Stores the uploaded file for report items requiring file input.
 * 
 * FUNCTIONALITY:
 * - handleCheckboxChange: Handles checking/unchecking of individual or all report items.
 * - handleSelectChange: Updates the report item selections based on user input.
 * - handleFileUpload: Handles file uploads for report items that require additional file input.
 */

import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { HiPlus } from 'react-icons/hi';
import { ReportSelect } from 'components/lib';
import { calculateTotalPrice, calculatePricePerAsset } from 'utils/priceUtils';
import styles from './ReportStepUseCaseSelection.module.scss';

const Checkbox = ({ name, label, checked, onChange }) => (
    <label className={styles['checkbox-label']}>
        <input className={styles['checkbox-input']} type="checkbox" name={name} checked={!!checked} onChange={onChange} />
        {label}
    </label>
);

const TableRow = ({ checkboxName, checked, onCheckboxChange, items, price, errors }) => (
    <tr>
        <td>
            <Checkbox name={checkboxName} checked={checked} onChange={onCheckboxChange} />
        </td>
        {items.map((item, index) => (
            <td key={index}>
                {item.type === 'blank' && <></>}
                {item.type === 'text' && item.content}
                {item.type === 'select' && (
                    <div>
                        <ReportSelect
                            name={item.name}
                            options={item.options}
                            value={item.value}
                            onChange={item.onChange}
                        />
                        {errors[`${checkboxName}${item.name}`] && (
                            <div className="errorText">{errors[`${checkboxName}${item.name}`]}</div>)}
                    </div>
                )}
            </td>
        ))}
        <td>{price}</td>
    </tr>
);

const itemPrices = {
    validatedAssetArea: 50,
    annualScope1GhfEmissions: 350,
    monthlyScope1GhEmissions: 150,
    scope1GasBreakdown: 350,
    annualScope2GhgEmissions: 200,
    emissionsOffsets: 200,
    dataFile: 0,
    analyticsPlatform: 25,
    pdfReport: 10,
};

function ReportStepUseCaseSelection({ formData, setFormData, nextStep, prevStep, errors }) {
    const [allChecked, setAllChecked] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [pricePerAsset, setPricePerAsset] = useState(0);


    const handleFileUpload = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet);

                setFormData((prevFormData) => ({
                    ...prevFormData,
                    uploadedBillData: jsonData
                }));
            };
            reader.onerror = (error) => {
                console.error('Error reading file:', error);
            };

            reader.readAsArrayBuffer(file);
        }
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        if (name === 'AllCheck') {
            setAllChecked(checked);
            setFormData((prev) => {
                const updatedFormData = { ...prev };
                reportItems.forEach(item => {
                    updatedFormData[item.checkboxName] = checked;
                });
                return updatedFormData;
            });
        } else {
            setFormData((prev) => ({
                ...prev,
                [name]: checked,
            }));
        }
    };

    const handleSelectChange = (e, itemKey, field) => {
        const { value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [itemKey]: {
                ...prev[itemKey],
                [field]: value,
            },
        }));
    };

    const calculateSpecificationsPrice = () => {
        let price = 0;
        if (formData.analyticsPlatform) price += 25;
        if (formData.pdfReport) price += 10;
        return price;
    };

    const reportItems = [
        {
            id: 'validatedAssetArea',
            checkboxName: 'validatedAssetArea',
            checked: formData.validatedAssetArea,
            items: [
                { type: 'text', content: <span>Validated Asset Area</span> },
                {
                    type: 'select',
                    name: 'Units',
                    value: formData.validatedAssetArea?.Units || "",
                    options: ['Square Meters', 'Acres'],
                    onChange: (e) => handleSelectChange(e, 'validatedAssetArea', 'Units')
                },
                { type: 'blank' },
                { type: 'blank' }
            ],
            price: '$ 50.00'
        },
        {
            id: 'annualScope1GhfEmissions',
            checkboxName: 'annualScope1GhfEmissions',
            checked: formData.annualScope1GhfEmissions,
            items: [
                { type: 'text', content: <span>Annual Scope 1 GHH Emissions</span> },
                {
                    type: 'select',
                    name: 'Year',
                    value: formData.annualScope1GhfEmissions?.Year || "",
                    options: ['2023', '2024'],
                    onChange: (e) => handleSelectChange(e, 'annualScope1GhfEmissions', 'Year')
                },
                {
                    type: 'select',
                    name: 'Units',
                    value: formData.annualScope1GhfEmissions?.Units || "",
                    options: ['Metric Tons'],
                    onChange: (e) => handleSelectChange(e, 'annualScope1GhfEmissions', 'Units')
                },
                { type: 'blank' }
            ],
            price: '$ 350'
        },
        {
            id: 'monthlyScope1GhEmissions',
            checkboxName: 'monthlyScope1GhEmissions',
            checked: formData.monthlyScope1GhEmissions,
            items: [
                { type: 'text', content: <span>Monthly scope 1 GHG Emissions</span> },
                {
                    type: 'select',
                    name: 'Year',
                    value: formData.monthlyScope1GhEmissions?.Year || "",
                    options: ['2023', '2024'],
                    onChange: (e) => handleSelectChange(e, 'monthlyScope1GhEmissions', 'Year')
                },
                {
                    type: 'select',
                    name: 'Units',
                    value: formData.monthlyScope1GhEmissions?.Units || "",
                    options: ['Metric Tons'],
                    onChange: (e) => handleSelectChange(e, 'monthlyScope1GhEmissions', 'Units')
                },
                { type: 'blank' }
            ],
            price: '$ 150'
        },
        {
            id: 'scope1GasBreakdown',
            checkboxName: 'scope1GasBreakdown',
            checked: formData.scope1GasBreakdown,
            items: [
                { type: 'text', content: <span>Scope 1 Gas Breakdown</span> },
                {
                    type: 'select',
                    name: 'Year',
                    value: formData.scope1GasBreakdown?.Year || "",
                    options: ['2023', '2024'],
                    onChange: (e) => handleSelectChange(e, 'scope1GasBreakdown', 'Year')
                },
                {
                    type: 'select',
                    name: 'Units',
                    value: formData.scope1GasBreakdown?.Units || "",
                    options: ['Metric Tons'],
                    onChange: (e) => handleSelectChange(e, 'scope1GasBreakdown', 'Units')
                },
                {
                    type: 'select',
                    name: 'Gases',
                    value: formData.scope1GasBreakdown?.Gases || "",
                    options: ['CO2', 'CH4', 'N2O'],
                    onChange: (e) => handleSelectChange(e, 'scope1GasBreakdown', 'Gases')
                }
            ],
            price: '$ 350'
        },
        {
            id: 'annualScope2GhgEmissions',
            checkboxName: 'annualScope2GhgEmissions',
            checked: formData.annualScope2GhgEmissions,
            items: [
                { type: 'text', content: <span>Annual scope 2 GHG Emissions</span> },
                {
                    type: 'select',
                    name: 'Year',
                    value: formData.annualScope2GhgEmissions?.Year || "",
                    options: ['2023', '2024'],
                    onChange: (e) => handleSelectChange(e, 'annualScope2GhgEmissions', 'Year')
                },
                {
                    type: 'text',
                    content: (
                        <div className={styles['input-field']}>
                            <Checkbox
                                name="energyBills"
                                label="Energy Bills"
                                checked={formData.energyBills}
                                onChange={handleCheckboxChange}
                            />
                        </div>
                    )
                },
                {
                    type: 'text',
                    content: formData.annualScope2GhgEmissions ? ( 
                        <label className={styles['file-upload-label']}>
                            <input
                                type="file"
                                className={styles['file-input']}
                                onChange={handleFileUpload}
                            />
                            <HiPlus className={styles['add-asset-icon']} />
                            <span>Upload Bills</span>
                        </label>
                    ) : (
                        <span style={{ color: 'grey' }}>Upload not available</span> 
                    )
                }
            ],
            price: '$ 200'
        },
        {
            id: 'emissionsOffsets',
            checkboxName: 'emissionsOffsets',
            checked: formData.emissionsOffsets,
            items: [
                { type: 'text', content: <span>Emissions Offsets</span> },
                {
                    type: 'select',
                    name: 'Year',
                    value: formData.emissionsOffsets?.Year || "",
                    options: ['2023', '2024'],
                    onChange: (e) => handleSelectChange(e, 'emissionsOffsets', 'Year')
                },
                { type: 'blank' },
                { type: 'blank' }
            ],
            price: '$ 200'
        }
    ];

    const formatPrice = (price) => {
        return price.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    };

    useEffect(() => {
        const newTotalPrice = calculateTotalPrice(formData, itemPrices);
        const newPricePerAsset = calculatePricePerAsset(newTotalPrice, formData.assets.length);

        if (newTotalPrice !== totalPrice || newPricePerAsset !== pricePerAsset) {
            setTotalPrice(newTotalPrice);
            setPricePerAsset(newPricePerAsset);

            setFormData(prevData => ({
                ...prevData,
                totalPrice: newTotalPrice,
                pricePerAsset: newPricePerAsset,
            }));
        }
    }, [formData, totalPrice, pricePerAsset, setFormData]);

    return (
        <div className={styles['report-step']}>
            <div className={styles['section']}>
                <h4 className={styles['section-title']}>Select use case</h4>
                <p className={styles['section-subtitle']}>Check box next to the applicable use case</p>
                <div className={styles['checkbox-group']}>
                    <Checkbox
                        name="ghgReportingOrgLevel"
                        label="GHG Reporting: Organization Level"
                        checked={formData.ghgReportingOrgLevel}
                        onChange={handleCheckboxChange}
                    />
                    <Checkbox
                        name="ghgReportingProjectLevel"
                        label="GHG Reporting: Project Level"
                        checked={formData.ghgReportingProjectLevel}
                        onChange={handleCheckboxChange}
                    />
                    <Checkbox
                        name="ghgVerification"
                        label="GHG Verification"
                        checked={formData.ghgVerification}
                        onChange={handleCheckboxChange}
                    />
                    <Checkbox
                        name="other"
                        label="Other"
                        checked={formData.other}
                        onChange={handleCheckboxChange}
                    />
                </div>
            </div>
            {errors.useCase && <div className='errorText'>{errors.useCase}</div>}
            <div className={styles['section']}>
                <h4 className={styles['section-title']}>Select Report Specifications</h4>
                <p className={styles['section-subtitle']}>Check box next to each requested item</p>
                <div className={styles['checkbox-group']}>
                    <Checkbox
                        name="ghgProtocolAligned"
                        label="GHG Protocol Aligned"
                        checked={formData.ghgProtocolAligned}
                        onChange={handleCheckboxChange}
                    />
                    <Checkbox
                        name="isoStandard"
                        label="ISO 14064-1-2018"
                        checked={formData.isoStandard}
                        onChange={handleCheckboxChange}
                    />
                    <Checkbox
                        name="none"
                        label="None"
                        checked={formData.none}
                        onChange={handleCheckboxChange}
                    />
                </div>
            </div>
            {errors.reportSpecifications && <div className='errorText'>{errors.reportSpecifications}</div>}
            <div className={styles['section']}>
                <h4 className={styles['section-title']}>Select Report Items</h4>
                <div className={styles['section-ttls']}>
                    <p className={styles['section-subtitle']}>
                        Check box next to requested item, make additional selections
                    </p>
                    <p className={styles['section-note']}>
                        Note: Please submit separate orders if requested items vary depending on the asset.
                    </p>
                </div>
                <table className={styles['data-table']}>
                    <thead>
                        <tr>
                            <th>
                                <Checkbox
                                    name="AllCheck"
                                    label=""
                                    checked={allChecked}
                                    onChange={handleCheckboxChange}
                                />
                            </th>
                            <th>Report Items</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th>Price (per Asset)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reportItems.map((item, index) => (
                            <TableRow
                                key={index}
                                checkboxName={item.checkboxName}
                                checked={item.checked}
                                onCheckboxChange={handleCheckboxChange}
                                items={item.items}
                                price={item.price}
                                errors={errors}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
            {errors.reportItems && <div className='errorText'>{errors.reportItems}</div>}
            <div className={styles['section']}>
                <table className={styles['specs-table']}>
                    <tbody>
                        <tr>
                            <td>
                                <h4 className={styles['section-title']}>Select Report Specifications</h4>
                                <p className={styles['section-subtitle']}>Check box next to each requested item</p>
                            </td>
                            <td>
                                <Checkbox
                                    name="dataFile"
                                    label="Data File"
                                    checked={formData.dataFile}
                                    onChange={handleCheckboxChange}
                                />
                            </td>
                            <td>
                                <Checkbox
                                    name="analyticsPlatform"
                                    label="Analytics Platform"
                                    checked={formData.analyticsPlatform}
                                    onChange={handleCheckboxChange}
                                />
                            </td>
                            <td>
                                <Checkbox
                                    name="pdfReport"
                                    label="PDF Report"
                                    checked={formData.pdfReport}
                                    onChange={handleCheckboxChange}
                                />
                            </td>
                            <td>$ {calculateSpecificationsPrice().toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {errors.reportSpecificationsOutput && <div className='errorText'>{errors.reportSpecificationsOutput}</div>}
            <div className={styles['section']}>
                <table className={styles['total-price']}>
                    <tbody>
                        <tr>
                            <td><div className={styles['total-price-title']}>Total Price (USD)</div></td>
                            <td><div className={styles['total-price-value']}><p>{formatPrice(totalPrice)}</p><p>({formatPrice(pricePerAsset)} Per asset)</p></div></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ReportStepUseCaseSelection;